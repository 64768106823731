import React, {useState} from 'react';
import './Home.css';

import { Button } from '../../../components';
import {homeContent, images} from '../../../constants';

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import GetPrayerTimes from "../../../queries/GetPrayerTimes";
import GetHadith from "../../../queries/GetHadith";



const Home = () => {
    const {t} = useTranslation()
    const [hadiths , setHadiths] = useState([])

    /*
    const query = useQuery({
        queryKey:"getHadithRequest" ,
        queryFn: async () => {
            return await GetHadith()
        },
        onSuccess:(data) => {
            const hadithsData = data.hadiths.data
            const index1 = Math.random() * 25
            const index2 = Math.random() * 25
            const index3 = Math.random() * 25
            setHadiths([
                {
                    id: 1,
                    image: images.home1,
                    title: hadithsData[index1].hadithEnglish,
                    content: hadithsData[index1].book.bookName,
                },
                {
                    id: 2,
                    image: images.home2,
                    title: hadithsData[index2].hadithEnglish,
                    content: hadithsData[index2].book.bookName,
                },
                {
                    id: 3,
                    image: images.home3,
                    title: hadithsData[index3].hadithEnglish,
                    content: hadithsData[index3].book.bookName,
                },

            ])
        }
    })*/
  return (

    <section className='home' id='home'>

      <div className='home-slider'>

      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Autoplay]}
        loop={true}
        autoplay={{ delay: 10000, disableOnInteraction: true }}
        navigation
        pagination={{ clickable: true }}
        >
          {
            homeContent.map((home) => {
              return (
              <SwiperSlide className='home-item' key={home.id}>

                <img src={home.image} alt='home-pic' />

                <div className='content'>
                  <div className='text'>
                    <h3>{t(home.title)}</h3>
                    <p>{t(home.titlefr)}</p>
                    <p>{t(home.content)}</p>
                    <Button link={'contact-us'} title={t('contact us')} />
                  </div>
                </div>

              </SwiperSlide>
              )
            })
          }

        </Swiper>

      </div>

    </section>

  )
}

export default Home;
