import React from 'react';
import './Events.css';

import {Button, Event, Heading} from '../../../components';
import {eventContent} from '../../../constants';
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";
import {useTranslation} from "react-i18next";
import {getImageFromData} from "../../../utils/Utils";


const Events = () => {

    const size = 3;
    const events = eventContent.slice(0, size);

    const getSectionItems = useQuery({
        queryKey: "GetSectionItems",
        queryFn: async () => {
            return await GetSectionItemsQuery("EVENT")
        },
        onSuccess: (data) => {
            console.log("data ", data)
        },
        onError: (err) => {
            console.log("error ", err)
        }

    })

    const {t} = useTranslation()

    return (
        <>

            {getSectionItems.isSuccess && getSectionItems.data.length !== 0 && <section className='events'>

                <Heading title={t('our events')}/>

                <div className='box-container'>
                    {
                        getSectionItems.isSuccess && getSectionItems.data.map((event, index) => {
                            return (

                                <Event
                                    key={event.id}
                                    image={getImageFromData(event, 0 , events[index]?.image )}
                                    title={event.title}
                                    content={event.subTitle}
                                    date={null}
                                    time={event.displayDate}
                                    location={event.location}
                                    event={event}
                                />)
                        })
                    }
                </div>
                {/*<div className={"see-more-btn"}>
                    <Button link={'events'} title={t('See all')}/>
                </div>*/}

            </section>}
        </>
    )
}

export default Events;
