import React from 'react';
import './Comments.css'; 

import { Comment} from '../../components';

 
const Comments = (props) => {
     
    const {blog} = props;
    const blogComments = blog.comments;  
    
    return (
    <div className='comments'> 
        <h4>3 comments</h4> 

        <div className='box-container'>
        {
            blogComments.map((comment) => {
                const replyComments = comment.replies;
                return(
                    <div className='main' key={comment.id}>
                        <Comment  
                            key={comment.id} 
                            image={comment.image} 
                            name={comment.name} 
                            date={comment.date}
                            content={comment.content} 
                        />

                        {replyComments.map((reply) => { 
                        return(
                            <div className='replies'>
                                <Comment  
                                key={reply.id} 
                                image={reply.image} 
                                name={reply.name} 
                                date={reply.date}
                                content={reply.content} 
                                />
                            </div>
                            )
                        })
                        }
                    </div>)
                
            })
        }


        </div>
    </div>
  )
}

export default Comments;