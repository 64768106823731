import Modal from 'react-modal';

const EventReactionModal = ({isVisible , title, content, hasTextInput , hasTextArea, onValid, onHide , setName , setNote , name, note , error}) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: "85%",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    return (<Modal
        isOpen={isVisible}
        onAfterOpen={() => {
        }}
        onRequestClose={() => {
          onHide()
        }}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <div style={{display: "flex",
          flexDirection: "column" , gap: 20 ,
          justifyContent:"center"
        }}>
            <div style={{display:"flex", flexDirection:"row" , justifyContent:"space-between"}}>
              <h2>{title}</h2>
              <button onClick={() => {
                onHide()
              }}>X
              </button>
            </div>
            <div>{content}</div>

            <p style={{color:"red"}}>{error}</p>
            <form>
              {hasTextInput && <input style={{
                width: "100%",
                borderRadius: 5,
                border: 1 ,
                borderColor:"lightgray" ,
                borderWidth: 2, borderStyle: "solid"}} placeholder={"Nom , e-mail "}
                onChange={(e) => {
                    setName(e.target.value)
                }}
              /> }
            </form>
            <form>
              {hasTextArea && <textarea style={{
                width: "100%",
                minHeight: "100px",
                borderRadius: 5,
                border: 1 ,
                borderColor:"lightgray" ,
                borderWidth: 2, borderStyle: "solid"}} placeholder={"Réagisser"}
                                        onChange={(e) => {
                                            setNote(e.target.value)
                                        }}
              > </textarea>}
            </form>
          <button className={"btn"} onClick={onValid}>Envoyer</button>
        </div>
    </Modal>)
}
export default EventReactionModal
