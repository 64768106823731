import React from 'react';

import { Button, PageTitle } from '../../../components';



const EditProfile = () => {
  return ( 
    <>
      <PageTitle title={'edit profile'} page={'edit profile'} />
      <section className='edit-profile'>
        <form className='form account' action='example.php'>
          <h3>edit profile</h3>
          <input type="text" name="name" placeholder="full name" id="name" className="box" />
          <input type="email" name="email" placeholder="email address" id="email" className="box" />
          <input type="text" name="number" placeholder="phone number" id="number" className="box" />
          <input type="file" name="profile-pic" id="profile-pic" accept="image/*" className="box" />
          <button type="submit" name="submit" id="submit"><Button title={"edit profile"} /></button>
        </form>
      </section>
    </>
  )
}

export default EditProfile 