import React from 'react';
import './Footer.css';

import {Logo} from '../../components';

import {FaEnvelope, FaFacebookF, FaInstagram, FaLinkedin, FaTwitter} from 'react-icons/fa';
import {AiTwotonePhone} from 'react-icons/ai';
import {BsMapFill} from 'react-icons/bs';

import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";


const Footer = () => {
    const {t} = useTranslation()

    return (
        <footer className='footer'>

            <div className='box-container'>

                <div className='footer-item'>
                    <Logo/>
                    <p></p>
                </div>


                <div className='footer-item'>
                    {false && <> <h2>{t("useful links")}</h2>
                        <div className='info links pages'>
                            <div className='links-item'>
                                <p><Link to='/'>{t("home")}</Link></p>
                                <p><Link to='/causes'>{t("causes")}</Link>
                                </p>
                                <p><Link to='/events'>{t("events")}</Link></p>
                                <p><Link to='/contact-us'>{t("contact")}</Link></p>
                            </div>
                        </div>
                    </>}
                </div>


                <div className='footer-item'>
                    <h2>Informations</h2>
                    <div className='info'>
                        <p><AiTwotonePhone className='icon'/><span>+33 06 95 38 84 36</span></p>
                        <p><FaEnvelope className='icon'/><span className='gmail'>centre.musulman.issy@gmail.com</span>
                        </p>
                        <p><BsMapFill className='icon'/><span>103 Av. de Verdun, 92130 Issy-les-Moulineaux</span></p>
                    </div>
                    <div className='social'>
                        <a href="https://www.facebook.com/cmissy92" target={"_blank"}><FaFacebookF
                            className='icon'/></a>
                        <a href="https://twitter.com/musulman_issy" target={"_blank"}><FaTwitter className='icon'/></a>
                        {
                            false && <>
                                <a href="/#"><FaInstagram className='icon'/></a>
                                <a href="/#"><FaLinkedin className='icon'/></a>
                            </>
                        }
                    </div>
                </div>

            </div>

            <div className='content'>
                <p><span>CMI-ISSY</span> | Tout les droits réservés</p>
            </div>

        </footer>
    )
}

export default Footer;
