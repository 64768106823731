import React from 'react';
import './Product.css'; 

import { BsCartPlusFill } from 'react-icons/bs';
import { FiHeart } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';

import { Link } from 'react-router-dom';   
   
    
 
const Product = ({image, name, product, content, disprice, price}) => { 

  return (
    <div className='product-item'>
      <div className='image'>
        <div className='options'>
          <Link to='/cart'><BsCartPlusFill className='icon' /></Link>
          <Link to='/wishlist'><FiHeart className='icon' /></Link>
          <Link to={{
              pathname: '/Shop/' + name,
            }} 
            location = {{product}}
            state= {product}><AiOutlineEye className='icon' />
          </Link>
        </div>
        <img src={image} alt='' />
      </div>
      <div className='content'> 
        <Link to={{
          pathname: '/Shop/' + name,
        }} 
        location = {{product}}
        state= {product}><h3>{name}</h3> 
        </Link>
        <p>{content}</p>
        <div className='price'>
          <p>${disprice} <span>${price}</span></p>
        </div>
      </div>
    </div> 
  )
} 

export default Product;