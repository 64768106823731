import { images } from '.'; 
 
const galleryContent = [
    {
        id: 1,
        image: images.gallery1,
        tag: 'learning',
        size: 'double',
    },
    {
        id: 2,
        image: images.gallery2,
        tag: 'community',
        size: 'single',
    },
    {
        id: 3,
        image: images.gallery3,
        tag: 'mosque',
        size: 'double',
    },
    {
        id: 4,
        image: images.gallery4,
        tag: 'learning',
        size: 'single',
    },
    {
        id: 5,
        image: images.gallery5,
        tag: 'community',
        size: 'single',
    },
    {
        id: 6,
        image: images.gallery6,
        tag: 'mosque',
        size: 'double',
    },
    {
        id: 7,
        image: images.gallery7,
        tag: 'community',
        size: 'single',
    },
    {
        id: 8,
        image: images.gallery8,
        tag: 'mosque',
        size: 'double',
    },
];

export default galleryContent;

