import { images } from '.';

const events = [ 
    { 
        id: 1,
        image: images.event1,
        title: 'What Are Ramadan Goals?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '10th Jan',
        time: '08:00 A.M. - 12:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event1speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event1speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event1speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },
    { 
        id: 2,
        image: images.event2,
        title: 'Tafseer of Quran',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '22th mar',
        time: '10:00 A.M. - 02:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event2speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event2speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                }, 
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event2speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },
    { 
        id: 3,
        image: images.event3,
        title: 'How to achieve Happiness?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '20th apr',
        time: '09:00 A.M. - 11:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event3speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event3speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event3speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },
    { 
        id: 4,
        image: images.event4,
        title: 'Depression and Mental Health',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '23th may',
        time: '01:00 P.M. - 05:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event4speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event4speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event4speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },
    { 
        id: 5,
        image: images.event5,
        title: 'Islamic Traits of Leadership',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '15th aug',
        time: '12:00 P.M. - 04:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event5speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event5speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event5speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },
    { 
        id: 6,
        image: images.event6,
        title: 'Anger Management',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date: '27th oct',
        time: '02:00 P.M. - 06:00 P.M.',
        venue: 'Banquet',
        location: 'Karachi, Pakistan',
        phone: '0123456789',
        email: 'abc@gmail.com',
        organizer: 'Islamic Center',
        cost: '100',
        paragraph: [
            {id: 1, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?'},
            {id: 2, title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'},
        ],
        eventContent: [ 
            {id: 1, title: 'Plans For Ramadan In Advance'},
            {id: 2, title: 'What Are The Overall Goals Of Ramadan'},
            {id: 3, title: 'How To Practice Ramadan'},
            {id: 4, title: 'Things To Learn From Ramadan Month'},
            {id: 5, title: 'Things To Do In Ramadan'},
            {id: 6, title: 'Things To Avoid In Ramadan'},
        ],
        speakers: [
            {
                id: 1,
                image: images.event6speaker1,
                name: 'Saleem Yousuf',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 2,
                image: images.event6speaker2,
                name: 'Zakir Khan',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
            {
                id: 3,
                image: images.event6speaker3,
                name: 'Shumaila Mushtaq',
                title: 'Volunteer',
                about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
                contacts: {
                    phone: '+123-456-789',
                    email: 'abc@gmail.com',
                    website: 'www.website.com',
                    address: 'karachi, pakistan',
                },
                accounts: {
                    facebook: 'https://www.facebook.com/',
                    twitter: 'https://twitter.com/',
                    instagram: 'https://www.instagram.com/',
                    youtube: 'https://www.youtube.com/',
                },
                skills: [ 
                    {id: 1, title: 'Knowledge Of Holy Quran'},
                    {id: 2, title: 'Expertise Of Teaching Quran'},
                    {id: 3, title: 'Problem Solving'},
                    {id: 4, title: 'Leadership'},
                    {id: 5, title: 'Creativity'},
                ],
            }, 
        ]
    },

];

export default events;