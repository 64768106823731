import React from 'react';
 
import { PageTitle, Gallery } from '../../../components';



const GalleryPage = () => {
  
  return (
    <>
      <PageTitle title={'Our Gallery'} page={'Our Gallery'} />
      <section>
        < Gallery />
      </section>
    </>

  )
}

export default GalleryPage;