import { images } from '.';

const orders = [
    { 
        id: 1,
        orderID: '#12345',
        date: 'feb 10th, 2022',
        status: 'completed',
        orderSummary: [
            {id: 1, name: 'sub total', value: '450.00'},
            {id: 2, name: 'delivery fee', value: '50.00'},
            {id: 3, name: 'discount', value: '20.00'},
            {id: 4, name: 'tax', value: '20.00'},
            {id: 5, name: 'total', value: '500.00'},
        ],
        items: [
            {
                id: 1,
                image: images.order1Item1,
                name: 'The holy Quran',
                price: '20.00',
                quantity: '2',
                total: '40.00',
            },
            {
                id: 2,
                image: images.order1Item2,
                name: 'Pure Honey',
                price: '30.00', 
                quantity: '2',
                total: '60.00',
            },
            {
                id: 3,
                image: images.order1Item3,
                name: 'Dates',
                price: '25.00',
                quantity: '2',
                total: '50.00',
            },
            {
                id: 4,
                image: images.order1Item4, 
                name: 'Attar',
                price: '50.00',
                quantity: '2',
                total: '100.00',
            },
            {
                id: 5,
                image: images.order1Item5,
                name: 'Prayer Mat',
                price: '100.00',
                quantity: '2',
                total: '200.00',
            },
        ]
    }, 
    {
        id: 2,
        orderID: '#23456', 
        date: 'mar 25th, 2022',
        status: 'completed',
        orderSummary: [
            {id: 1, name: 'sub total', value: '150.00'},
            {id: 2, name: 'delivery fee', value: '50.00'},
            {id: 3, name: 'discount', value: '20.00'},
            {id: 4, name: 'tax', value: '20.00'},
            {id: 5, name: 'total', value: '200.00'},
        ],
        items: [
            {
                id: 1,
                image: images.order2Item1,
                name: 'The holy Quran',
                price: '20.00',
                quantity: '3',
                total: '60.00',
            },
            {
                id: 2,
                image: images.order2Item2,
                name: 'Pure Honey',
                price: '30.00', 
                quantity: '3',
                total: '90.00',
            },
        ]
    },
    {
        id: 3,
        orderID: '#34567',
        date: 'may 14th, 2022',
        status: 'cancelled',
        orderSummary: [
            {id: 1, name: 'sub total', value: '200.00'},
            {id: 2, name: 'delivery fee', value: '50.00'},
            {id: 3, name: 'discount', value: '20.00'},
            {id: 4, name: 'tax', value: '20.00'},
            {id: 5, name: 'total', value: '250.00'},
        ],
        items: [
            {
                id: 1,
                image: images.order3Item1,
                name: 'The holy Quran',
                price: '20.00',
                quantity: '3',
                total: '60.00',
            },
            {
                id: 2,
                image: images.order3Item2,
                name: 'Pure Honey',
                price: '30.00', 
                quantity: '3',
                total: '90.00',
            },
            {
                id: 3,
                image: images.order3Item3,
                name: 'Dates',
                price: '25.00',
                quantity: '2',
                total: '50.00',
            },
        ]
    },
    {
        id: 4,
        orderID: '#45678',
        date: 'july 1st, 2022',
        status: 'processing',
        orderSummary: [
            {id: 1, name: 'sub total', value: '250.00'},
            {id: 2, name: 'delivery fee', value: '50.00'},
            {id: 3, name: 'discount', value: '20.00'},
            {id: 4, name: 'tax', value: '20.00'},
            {id: 5, name: 'total', value: '300.00'},
        ],
        items: [
            {
                id: 1,
                image: images.order4Item1,
                name: 'The holy Quran',
                price: '20.00',
                quantity: '2',
                total: '40.00',
            },
            {
                id: 2,
                image: images.order4Item2,
                name: 'Pure Honey',
                price: '30.00', 
                quantity: '2',
                total: '60.00',
            },
            {
                id: 3,
                image: images.order4Item3,
                name: 'Dates',
                price: '25.00',
                quantity: '2',
                total: '50.00',
            },
            {
                id: 4,
                image: images.order4Item4, 
                name: 'Attar',
                price: '50.00',
                quantity: '2',
                total: '100.00',
            },
        ]
    },
    {
        id: 5,
        orderID: '#56789',
        date: 'sep 23rd, 2022',
        status: 'processing',
        orderSummary: [
            {id: 1, name: 'sub total', value: '300.00'},
            {id: 2, name: 'delivery fee', value: '50.00'},
            {id: 3, name: 'discount', value: '20.00'},
            {id: 4, name: 'tax', value: '20.00'},
            {id: 5, name: 'total', value: '350.00'},
        ],
        items: [
            {
                id: 1,
                image: images.order5Item1,
                name: 'The holy Quran',
                price: '20.00',
                quantity: '15',
                total: '300.00',
            },
        ]
    },
];

export default orders;