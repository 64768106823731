import React from 'react';
import './Pillars.css';

import { Heading } from '../../../components';
import { pillars } from '../../../constants';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";



const Pillars = () => {
    const {t} = useTranslation()
  return (
    <section className='pillars linear-bg'>

      <Heading title={'pillars of islam'} />

      <div className='box-container'>
      {
        pillars.map((pillar) => {
          return <Link to={"/blog/fcaa7174-9d5b-4d54-a813-e96cb0dbec06"} className='pillar-item' key={pillar.id}>
            <div className='icon'>{pillar.icon}</div>
            <h4>{t(pillar.title)}</h4>
            <p>{t(pillar.content)}</p>
          </Link>
        })
      }
      </div>

    </section>
  )
}

export default Pillars;
