import React from 'react';
import './Event.css';

import { FaMap } from 'react-icons/fa';
import { BiTime } from 'react-icons/bi';

import { Link } from 'react-router-dom';



const Event = (props) => {
  const {image, title, content, date, time, location, event, key} = props;
  return (
    <div className='event-item'>

        <div className='image'>
          <img src={image} alt='Event' />
            {date !== null && <div className='date'>{date}</div>}
        </div>

        <div className='content'>

          <div className='details'>
            <h3><BiTime className='icon' /><span>{time}</span></h3>
              {location !== undefined && <h3><FaMap className='icon'/><span>{location}</span></h3>}
          </div>
          <Link
              to={`/Events/${event.id}`}
            state= {event}
            className='main-heading'>{title}
          </Link>
          <div>
              <p dangerouslySetInnerHTML={{__html:content}} />
          </div>

        </div>

    </div>
  )
}

export default Event;
