import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom'; 
import { FaCalendarAlt, FaUserAlt } from 'react-icons/fa';

     
   
const Blog = (props) => { 
  const {id, image, date, admin, heading, content, blog} = props;
  return (  
    <div className='blog-item' key={id}>

        <div className='image'>
          <img src={image} alt='Blog' />
        </div>
 
        <div className='content'>
          <div className='details'>
            <h3><FaCalendarAlt className='icon' /><span>{date}</span></h3>
            <h3><FaUserAlt className='icon' /><span>by {admin}</span></h3>
          </div>
          <Link to={{
            pathname: '/Blogs/' + heading,
          }} 
          state= {blog}
          className='main-heading'>{heading}
          </Link>
          <p>{content}</p>    
        </div>

    </div>
  )
}

export default Blog;