import React from 'react';

import { Event, PageTitle } from '../../../components';
import { eventContent } from '../../../constants';



const EventPage = () => { 

  const size = 6;   
  const events = eventContent.slice(0, size);

  return (
    <>
      <PageTitle title={'events'} page={'events'} />
      <section className='events'>
        <div className='box-container'>
        { 
          events.map((event) => {  
            return(
            <Event 
              key={event.id} 
              image={event.image} 
              title={event.title}
              content={event.content} 
              date={event.date}
              time={event.time}
              location={event.location} 
              event={event} 
            />) 
          })
        }
        </div>
      </section>
    </>
  )
}
 
export default EventPage;