import React, {useState} from 'react';
import './NavbarItem.css';

import { FaPlus, FaMinus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";



const NavbarItem = ({id, mainLink, subLink}) => {

    const [show, setShow] = useState(false);
    const {t} = useTranslation()

    return (
        <div className="nav-link" key={id}>
            <div className="main-nav-link" onClick={() => {setShow(!show)}}>
                {subLink.length ? <div className='nav-btn'>{t(mainLink.title)}</div>  : <Link className='nav-btn' to={mainLink.link}>{t(mainLink.title)}</Link> }
                {subLink.length > 0 && <div className='icon'>{show ? <FaMinus /> : <FaPlus />}</div>}
            </div>
            {(show && subLink.length > 0) && <p className="sub-nav-link">
            {
                subLink.map((item) => {
                    return (
                        <Link to={item.link} state={item.state} key={item.id}>{t(item.title)}</Link>
                    )
                })
            }
            </p>}
        </div>
    )
}

export default NavbarItem;
