import React, {useEffect, useState} from 'react';
import imageSrc1 from '../../assets/Popup/affiche1.jpeg'
import imageSrc2 from '../../assets/Popup/affiche2.jpeg'
import './Slider.css';

const SiteLoadPopup = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Delay popup to ensure everything is loaded
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 1000); // 1 second delay

        // Cleanup timer if component unmounts
        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleRegister = () => {
        // Example registration action
        alert('Registration clicked!');
        handleClose();
    };
    const images = [
        imageSrc1 , imageSrc2
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    if (!isOpen) return null;



    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}
            onClick={handleClose}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '30px',
                    borderRadius: '10px',
                    maxWidth: '500px',
                    width: '90%',
                    textAlign: 'center',
                    position: 'relative'
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
            >
                <button
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer'
                    }}
                >
                    ×
                </button>

                <h2 style={{marginBottom: '15px', color: '#333'}}>
                    Evenement majeur
                </h2>

                <div style={{marginBottom: '20px'}}>
                    <p><strong>Les merveilles de la langues arabes:</strong> </p>
                    <p>Venez fêter la langue arabe avec nous ! ✨ Le 14 décembre, de 10h à 19h, à la Maison des Hauts
                        d’Issy (16, rue de l’Abbé Derry), profitez d’une journée gratuite et conviviale pour petits et
                        grands : poésie, art, musique, calligraphie et saveurs à découvrir ensemble, en famille ou entre
                        amis ! 🎨🎶
                    </p>
                    <p>Centre Musulman d’Issy-les-Moulineaux /</p>
                    <p>#CultureArabe #IssyLesMoulineaux</p>
                    <div className="slider">
                        <button onClick={prevSlide} className="slider-button">❮</button>
                        <img
                            src={images[currentIndex]}
                            alt={`Slide ${currentIndex}`}
                            className="slider-image"

                        />
                        <button onClick={nextSlide} className="slider-button">❯</button>
                    </div>

                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px'
                }}>
                    <button
                        onClick={handleClose}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        OK
                    </button>

                </div>
            </div>
        </div>
    );
};

export default SiteLoadPopup;
