import React from 'react';
import './GalleryItem.css';
 
import { BsPlusLg } from 'react-icons/bs';

  
 
const GalleryItem = ({id, image, size, index, popup}) => {
  
  return (
    <div className={`gallery-item ${size}`} key={id}>
      <img src={image} alt='Gallery image' />
      <div className='content'>
        <a onClick={() => {popup(index)}}><BsPlusLg className='icon' /></a> 
      </div>
    </div> 
  )
}

export default GalleryItem;