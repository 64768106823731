import React from 'react';
import './SingleBlog.css';

import { BlogSidebar, Button, Comments, PageTitle } from '../../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaAngleRight } from 'react-icons/fa';
import { FaCalendarAlt, FaUserAlt } from 'react-icons/fa';
import { GoQuote } from 'react-icons/go';

import { useLocation } from "react-router-dom";
 
  

const BlogDetails = () => { 

  const location = useLocation();
  const blog = location.state;  

  const paragraphs = blog.paragraph; 

  return (   
    <>
      <PageTitle title={'single blog'} page={'single blog'} />
      <section className='blog-details'>

        <div className='blog-container'>

          <div className='blog-info' key={blog.id}>
            <div className='image'>
              <img src={blog.image} alt='Blog' />
            </div>
            <div className='content'> 

              <div className='details'>
                <h3><FaCalendarAlt className='icon' /><span>{blog.date}</span></h3>
                <h3><FaUserAlt className='icon' /><span>by {blog.admin}</span></h3>
              </div>

              <h3 className='main-heading'>{blog.heading}</h3>

              <p>{paragraphs.para1}</p>  
              <p>{paragraphs.para2}</p> 
              
              <div className='important'>
                <GoQuote className='icon' />
                {blog.important}
              </div>
 
              <p>{paragraphs.para3}</p>  

              <div className='blog-points'>
                <h4>Importance of Salah:</h4>
                <ul>
                {
                  (blog.points).map((point) => {
                    return <li className={point.id}><FaAngleRight className='icon' />{point.title}</li>
                  })
                }
                </ul>

              </div> 

              <div className='blog-gallery'>
              {
                (blog.gallery).map((item) => {
                  return <img src={item.image} alt='' />
                })
              }
              </div> 

              <div className='end-details'>
                <div className='tags'>
                  {
                    (blog.tags).map((item) => {
                      return <span>{item.title}</span>
                    })
                  }
                </div>

                <div className='share'>
                  <h3>share:</h3>
                  <FaFacebookF className='icon' />
                  <FaLinkedin className='icon' />
                  <FaTwitter className='icon' />
                  <FaInstagram className='icon' />
                </div>
              </div>
            </div>
          </div>
           
          <Comments blog={blog} />

          <div className='leave-reply'>
            <form className='form' action='comment.php'>
                <h3>leave a comment</h3>  
                <div className='input-box'>
                    <input type="text" name="name" className='box' id="name" placeholder="name" required />
                    <input type="email" name="email" className='box' id="email" placeholder="email" required />
                </div>
                <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
                <button type="submit" name="submit" id="submit"><Button title={"submit"} /></button>
            </form>
          </div>

        </div>

        <BlogSidebar />  

      </section>

    </>

  )
}

export default BlogDetails;