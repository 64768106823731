import React from 'react';
import './Comment.css'; 

import { BsReply } from 'react-icons/bs';


 
const Comment = (props) => {
     
    const {id, image, name, date, content} = props;
    
    return (
        <div className='comment' key={id}>
            <div className='image'>
                <img src={image} alt='' />
            </div>
            <div className='content'>
                <div className='intro'>
                    <div className='details'><h3>{name}</h3><h6>{date}</h6></div>
                    <div className='icon-item'><BsReply className='icon' /><span>reply</span></div>
                </div>
                <p className='text'>{content}</p>
            </div>
        </div>
    )
}

export default Comment;