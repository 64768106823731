import { images } from "../constants";

export const cartItems = [
    {
        id: 1,  
        image: images.cart1,
        name: 'The holy Quran',
        price: '20.00',
        quantity: '1',
        total: '20.00',
    },
    {
        id: 2,
        image: images.cart2,
        name: 'Pure Honey',
        price: '30.00', 
        quantity: '1',
        total: '30.00',
    },
    {
        id: 3,
        image: images.cart3,
        name: 'Dates',
        price: '25.00',
        quantity: '2',
        total: '50.00',
    },
    {
        id: 4,
        image: images.cart4,
        name: 'Attar',
        price: '50.00',
        quantity: '2',
        total: '100.00',
    },
    {
        id: 5,
        image: images.cart5,
        name: 'Prayer Mat',
        price: '100.00',
        quantity: '1',
        total: '100.00',
    },
];


export const cartSummary = [
    {id: 1, name: 'sub total', value: '300.00'},
    {id: 2, name: 'delivery fee', value: '50.00'},
    {id: 3, name: 'discount', value: '20.00'},
    {id: 4, name: 'tax', value: '20.00'},
    {id: 5, name: 'total', value: '350.00'},
];