import React from 'react';
import './Register.css';

import { Button, PageTitle } from '../../../components';

import { Link } from 'react-router-dom';



const Register = () => { 
  return ( 
    <>
      <PageTitle title={'register'} page={'register'} />
      <section className='register'>

        <form className='form account' action="example.php">
            <h3>register</h3>
            <input type="text" name="name" placeholder="enter your name" id="name" className='box' />
            <input type="email" name="email" placeholder="enter your email" id="email" className='box' />
            <input type="password" name="password" placeholder="enter your password" id="password" className='box' />
            <input type="password" name="repeat-password" placeholder="repeat your password" id="repeat-password" className='box' />
            <div className='terms'>
                <input type="checkbox" name="remember-me" id="remember-me" />
                <label htmlFor="remember-me"> i agree with the <span>terms & conditions</span> </label>
            </div>
            <button type="submit" name="submit" id="submit"><Button title={"register"} /></button>
            <p>already have an account? <Link to='/login' className='link'>login</Link></p>
        </form>
 
      </section>
    </>

  )
}

export default Register;