import React from 'react';
import './GalleryList.css';



const GalleryList = ({id, title, active, setSelected}) => {
  return ( 
    <li
        key={id} 
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        {title}
    </li>
  )
} 

export default GalleryList;