import axios from "axios";

const GetPrayerTimes = async () => {
    const date = new Date()
    //const url = `https://api.aladhan.com/v1/calendarByAddress/${date.getFullYear()}/${date.getMonth() + 1}?address=103 Av. de Verdun, 92130 Issy-les-Moulineaux&method=99&methodSettings=17,null,13.9`

    const url = `https://api.aladhan.com/v1/calendarByAddress/${date.getFullYear()}/${date.getMonth() + 1}?address=103 Av. de Verdun, 92130 Issy-les-Moulineaux&method=99&methodSettings=12.6,1.1,15.8`
    console.log(url)
    const api = axios.create({
        baseURL: url
    })
    const response = await api.get("")
    return response.data
}

export default GetPrayerTimes
