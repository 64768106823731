import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import {initIcons} from "./icons";


const root = ReactDOM.createRoot(document.getElementById("root"));
initIcons()
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
);

