import { FaHandPointUp, FaPray, FaHandHoldingUsd, FaKaaba } from 'react-icons/fa';
import { BsMoonFill } from 'react-icons/bs';


const pillars = [
    {
        id: 1,
        icon: <FaHandPointUp />,
        title: 'shadah',
        content: '',
    },
    {
        id: 2,
        icon: <FaPray />,
        title: 'prayer',
        content: '',
    },
    {
        id: 3,
        icon: <BsMoonFill />,
        title: 'fasting',
        content: '',
    },
    {
        id: 4,
        icon: <FaHandHoldingUsd />,
        title: 'zakat',
        content: '',
    },
    {
        id: 5,
        icon: <FaKaaba />,
        title: 'hajj',
        content: '',
    },

];

export default pillars;
