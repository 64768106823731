import React from 'react'; 
import './Testimonials.css';  

import { Heading } from '../../../components';
import { testiContent } from '../../../constants';

import { FaQuoteRight } from 'react-icons/fa'; 
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'; 

// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles  
import 'swiper/css';
import 'swiper/css/pagination'; 
import 'swiper/css/navigation';
 
 

const Testimonials = () => { 
  return (
    <section className='testimonials'>

      <Heading title={'testimonials'} />

      <div className='testimonial-slider'>
      <Swiper 
      // install Swiper modules
      modules={[Pagination, Autoplay]}
      spaceBetween={150}
      loop={true}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      >
        {
          testiContent.map((testimonial) => { 
            return (
              <SwiperSlide className='testi-item' key={testimonial.id}>

                <FaQuoteRight className='top-quote'/>

                <p>
                  <RiDoubleQuotesL className='icon'/>
                  {testimonial.content}
                  <RiDoubleQuotesR className='icon'/>
                </p>
                
                <div className='text'>
                  <h4>{testimonial.name}</h4>
                </div>

              </SwiperSlide>
            )
          })
        }

      </Swiper>
      </div>
      
    </section>

  )
} 

export default Testimonials