import React from 'react';
import './Cause.css';

import { Link } from 'react-router-dom';
import { AiTwotoneCalendar } from 'react-icons/ai';
import Button from "../Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



const Cause = (props) => {
  const {image, title, date, content, raised, goal, cause , link} = props;
  return (
    <div className='cause-item'>

        <div className='image'>
          <img src={image} alt='Cause' />
        </div>

        <div className='content'>
          <div className={"row space-between"}>
              <Link to={`/Causes/${cause.id}`}
                    state= {cause}
                    className='main-heading'>{title}
              </Link>
              <Button link={link} target={"_blank"} title={<FontAwesomeIcon icon={{iconName:"hand-holding-dollar" , prefix:"fas"}} />} />
          </div>
          <div className='date'><AiTwotoneCalendar className='icon' /><span>{date}</span></div>
          <p dangerouslySetInnerHTML={{__html:content}}></p>
          <div className='skill-bar'><span className='skill-progress' style={{width: Math.floor((raised * 100) / goal) + `%`}}><span className='skill-no'>{Math.floor((raised * 100) / goal)}%</span></span></div>
          <div className='amount'>
            <p>Raised: <span>${raised}</span></p>
            <p>Goal: <span>${goal}</span></p>
          </div>
        </div>

    </div>
  )
}

export default Cause;
