import React from 'react';
import './SingleProduct.css';

import { Button, PageTitle, Product, Quantity } from '../../../components';
import { related } from '../../../constants';

import { FaStar } from 'react-icons/fa';
import { useLocation } from "react-router-dom";

import ImageGallery from 'react-image-gallery';

 
  
const ProductDetails = () => {

  const location = useLocation();  
  const product = location.state; 


  const image = [  
    {
      original: product.gallery.image1,
      thumbnail:  product.gallery.image1,
    },
    {
      original: product.gallery.image2,
      thumbnail:  product.gallery.image2,
    },
    {
      original: product.gallery.image3,
      thumbnail:  product.gallery.image3,
    },
    {
      original: product.gallery.image4,
      thumbnail:  product.gallery.image4,
    },
  ];

  return (
      <>
        <PageTitle title={'product details'} page={'product details'} />
        <section className='single-product'>

          <div className='product-des'>
            <div className='image'>
              <ImageGallery items={image} />
            </div>
              <div className='content'>
                <div className='text'>

                  <h3>{product.name}</h3> 

                  <div className='price'>${product.disprice}/- <span>${product.price}</span></div>

                  <div className='intro'>
                      <div className='rating'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
                      <span>({product.reviews}) reviews</span>
                  </div>

                  <p>{product.content}</p> 

                  <div className='qty'>
                    <h4>Quantity:</h4>
                    <Quantity quantity={1} />  
                  </div>

                  <Button link={'/cart'} title={'add to cart'} />

                  <div className='categories'> 
                    <h4>Categories:</h4>
                    {
                      (product.category).map((item) => {
                        return(<span key={item}>{item}</span>)
                      }) 
                    }
                  </div>

                  <div className='tags'>
                  <h4>Tags:</h4>
                  {
                    (product.tags).map((item) => {
                      return(<span key={item}>{item}</span>)
                    })
                  }
                  </div>
                      
                </div>
              </div>
             
            </div>

            <div className='related-items'>

              <div className='sidebar-heading'>
                  <h2>Related Products</h2>
              </div>

              <div className='box-container'>
                {
                  related.map((product) => {
                    return(
                      <Product  
                      key={product.id} 
                      image={product.image}
                      name={product.name}
                      disprice={product.disprice}
                      price={product.price}
                      product={product} />
                    )
                  })
                }
              </div>

          </div>

        </section>
    </>
  )
}

export default ProductDetails;