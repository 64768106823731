import { images } from "../constants";

const relatedItems = [
    {
        id: 1,
        image: images.product2Gallery1, 
        name: 'Pure Honey',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.product2Gallery1,
            image2: images.product2Gallery2,
            image3: images.product2Gallery3,
            image4: images.product2Gallery4,
        },
    },
    {
        id: 2,
        image: images.product3Gallery1, 
        name: 'Dates',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.product3Gallery1,
            image2: images.product3Gallery2,
            image3: images.product3Gallery3,
            image4: images.product3Gallery4,
        },
    },
    {
        id: 3,
        image: images.product4Gallery1, 
        name: 'Attar',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.product4Gallery1,
            image2: images.product4Gallery2,
            image3: images.product4Gallery3,
            image4: images.product4Gallery4,
        },
    },
    {
        id: 4,
        image: images.product5Gallery1, 
        name: 'Prayer Mat',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.product5Gallery1,
            image2: images.product5Gallery2,
            image3: images.product5Gallery3,
            image4: images.product5Gallery4,
        },
    },
];

export default relatedItems;