import React, {useState} from 'react';
import './Prayers.css';

import { Heading } from '../../../components';
import { prayers } from '../../../constants';
import {useQuery} from "react-query";
import GetPrayerTimes from "../../../queries/GetPrayerTimes";



const Prayers = () => {
    const [todayData , setTodayData] = useState([])
    /*
    const query = useQuery({
        queryKey:"getPrayerTimeRequest" ,
        queryFn: async () => {
            return await GetPrayerTimes()
        },
        onSuccess:(data) => {
            console.log("all data",data)
            const today = new Date()
            console.log(today.getDate())
            let dayNumber= `${today.getDate()}`
            if(today.getDate() < 10){
                dayNumber = `0${today.getDate()}`
            }
            console.log(dayNumber)
            const currentDayData =data.data.filter((value ) => {
                return value.date.gregorian.day === dayNumber
            })[0]
            console.log("today ", currentDayData)
            setTodayData( [
                {
                    id: 1,
                    name: 'fajr',
                    start: currentDayData.timings.Fajr,
                    iqamah: '+10 min',
                },
                {
                    id: 2,
                    name: 'sunrise',
                    start: currentDayData.timings.Sunrise,
                    iqamah: '',
                },
                {
                    id: 3,
                    name: 'dhuhr',
                    start: currentDayData.timings.Dhuhr,
                    iqamah: "+10 min",
                },
                {
                    id: 4,
                    name: 'asr',
                    start: currentDayData.timings.Asr,
                    iqamah: '+10 min',
                },
                {
                    id: 5,
                    name: 'maghrib',
                    start: currentDayData.timings.Maghrib,
                    iqamah: '+5 min',
                },
                {
                    id: 6,
                    name: 'isha',
                    start: currentDayData.timings.Isha,
                    iqamah: '+ 10 min',
                },

            ])
        }
    })*/
  return (<>
    <section className='prayer-timing linear-bg'>

      <Heading title={'Horaire des prières'} />




    </section>
          <div style={{ position: 'relative', width: '100%', aspectRatio: '16 / 9' }}>
              <iframe
                  src="https://mawaqit.net/fr/w/ascmi-moulineaux"
                  style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                  }}
                  allowFullScreen
                  title="Prayer Times"
              ></iframe>
          </div>
    </>
  )
}

/*
* {
         false &&  todayData.map((prayer) => {
          return(

          <div className='prayer-item' key={prayer.id}>
            <h4>{prayer.name}</h4>
            <ul className='content'>
              <li><span>{prayer.start}</span></li>
              {prayer.iqamah.length > 0 && <li><h6>iqamah:</h6><span>{prayer.iqamah}</span></li>}
            </ul>
          </div>
          )
        })
        }
        </div>
* */

export default Prayers;
