import React from 'react'; 
import './Team.css'; 

import { Heading, TeamItem } from '../../../components';
import { teamContent } from '../../../constants';
 
   
  
const Team = () => { 
  return (
    <section className='team'>

      <Heading title={'our team'} />

      <div className='box-container'> 
      { 
        teamContent.map((team) => { 
        return(
          <TeamItem 
            key={team.id} 
            image={team.image}  
            name={team.name}
            title={team.title}
            accounts={team.accounts}
            team={team}  
          />)
        })
      } 
      </div>

    </section>
  )
}

export default Team;