import React, {Component} from 'react';
import axios from "axios";
import './ContactUs.css';

import { PageTitle, Button } from '../../components';
import { contactContent } from '../../constants';
import {useTranslation} from "react-i18next";
import Network from "../../queries/Network";

const API_PATH = 'https://www.example.com/api/phpmailer/submit.php';



class Contact extends Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      mailSent: false,
      error: null
    }
  }

  // when submit btn is clicked
  handleSubmitForm = (e) => {

    e.preventDefault();

    const submitBtn = document.querySelector('#submit .btn');
    submitBtn.innerText = 'please wait';

    this.setState({
      mailSent: false
    })

    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("message", this.state.message);

    Network().post("/public/contact" , formData)
    .then(result => {
        console.log(result)
        this.setState({
          mailSent: result.data.sent,
          name: '', email: '', subject: '', message: ''
        })
        submitBtn.innerText = 'Envoyer un message';
    })
    .catch(error => this.setState({ error: error.message }));
    console.log(this.state);
  }
  render(){
   return (
    <>

    <PageTitle title={'Contacter nous'} page={'contact'} />
     <section className='contact' id='contact'>

      <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=103%20Av.%20de%20Verdun,%2092130%20Issy-les-Moulineaux+(CMI%20Issy%20les%20Moulineaux)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          width="600" height="450" allowFullScreen loading="lazy"></iframe>

      <div className='contact-container'>

        <div className='box-container'>

          <div className='contact-info'>

            <h3>Information</h3>
            {
            contactContent.map((contact) => {
              let details = contact.content;
              return <div className='info-item' key={contact.id}>
                <div className='intro'>
                  <div className='icon'>{contact.icon}</div>
                  <h4>{contact.title}</h4>
                </div>
                <div className='content'>
                  {details.map(item => {return <p key={item.id}>{item.value}</p>})}
                </div>
              </div>
            })
            }
          </div>

          <form method="post" className='contact-form' id="contactUs-Form">

            <h3>Envoyer un message</h3>

            <input type="text" name="name" className='box' id="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} placeholder="name" required />
            <input type="email" name="email" className='box' id="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}  placeholder="email" required />
            <input type="text" name="subject" className='box' id="subject" value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })} placeholder="subject" />
            <textarea cols="30" rows="10" name="comment" id="comment" className='box' value={this.state.message} onChange={e => this.setState({ message: e.target.value })} placeholder="message"></textarea>
            <div className='msg-alert'>

              <button type="submit" onClick={e => this.handleSubmitForm(e)} name="submit" id="submit"><Button title={"send message"} /></button>

              {this.state.mailSent &&
                  <span className="alert">Message sent successfully.</span>
              }

            </div>

          </form>

        </div>

      </div>

     </section>
     </>
   )
 }
}

 export default Contact
