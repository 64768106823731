import React, {useState} from 'react';
import './Causes.css';

import {Button, Cause, Heading} from '../../../components';
import {causeContent, images} from '../../../constants';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";
import {getImageFromData} from "../../../utils/Utils";


const Causes = () => {

    const [allCauses, setAllCauses] = useState([])

    const getSection = useQuery({
        queryKey: "getSection cause",
        queryFn: async () => {
            return await GetSectionItemsQuery("CAUSE")
        },
        onSuccess: (data) => {
            console.log("cause result")
            console.log("data cause", data)
            const arr = data.map(value => {
                let cible = ""
                let causePaymentLink = ""

                try {
                    const tags = JSON.parse(value.tags)
                    console.log(tags)
                    let target = tags.map(v => {
                        if (v.target !== undefined && v.target !== null) {
                            cible = v.target
                        }
                    })

                    let paymentLink = tags.map(v => {
                        if (v.paymentLink !== undefined && v.paymentLink !== null) {
                            causePaymentLink = v.paymentLink
                        }
                    })
                    console.log("target ok ", cible)
                } catch (err) {
                    console.log(err)
                }
                console.log(value.imageUrls[0])
                const item = {
                    id: value.id,
                    image: getImageFromData(value, 0, images.cause2),
                    title: value.title,
                    date: "",
                    content: value.description,
                    paragraph: value.description,
                    raised: '0',
                    goal: cible,
                    link: causePaymentLink
                }
                return item
            })
            setAllCauses(arr)
        },
        onError: (err) => {
            console.log("error cause ", err)
        }

    })

    const {t} = useTranslation()
    const size = 3;
    const causes = causeContent.slice(0, size);

    return (
        <>
            {allCauses.length !== 0 && <section className='causes'>

                <Heading title={t('our Causes')}/>

                <div className='box-container'>

                    {
                        allCauses.map((cause) => {
                            return (
                                <Cause
                                    key={cause.id}
                                    image={cause.image}
                                    title={cause.title}
                                    date={cause.date}
                                    content={cause.content}
                                    raised={cause.raised}
                                    goal={cause.goal}
                                    cause={cause}
                                    link={cause.link}
                                />)
                        })
                    }

                </div>
                <div className={"see-more-btn"}>
                    <Button link={'causes'} title={t('See more')}/>
                </div>
            </section>}
        </>
    )
}

export default Causes;
