import React from 'react'; 
import './TeamItem.css'; 

import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

import { Link } from 'react-router-dom'; 


    
const TeamItem = (props) => { 
    const {id, image, name, title, team} = props;
    return (
        <div className='team-item' key={id}>
            <div className='image'>
                <img src={image} alt='Team Pic' />
            </div>
            <div className='content'>
                <Link to={{
                        pathname: '/Team/' + name,
                    }}     
                    state= {team}>{name}
                </Link>
                <h5>{title}</h5> 
                <div className='line'></div>
                <div className='icon-container'>
                    <a className='icon' href='#'><FaFacebookF /></a>
                    <a className='icon' href='#'><FaTwitter /></a>
                    <a className='icon' href='#'><FaInstagram /></a>
                    <a className='icon' href='#'><FaYoutube /></a> 
                </div> 
            </div>
        </div>
    )
}

export default TeamItem;