import React from 'react';
import './Button.css';


const Button = ({title, link='' , target}) => {
  return (
    <a href={`${link}`} target={`${target}`} className='btn'>{title}</a>
  )
}

export default Button
