import React, {useState} from 'react';
import './Services.css';

import {Button, Heading} from '../../../components';

import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";


const Services = () => {
    const {t} = useTranslation()

    const [allServices, setAllServices] = useState([])

    const getSection = useQuery({
        queryKey: "getSection service",
        queryFn: async () => {
            return await GetSectionItemsQuery("SERVICE")
        },
        onSuccess: (data) => {
            console.log("cause result")
            console.log("data cause", data)
            const arr = data.map(value => {
                let cible = ""

                try {
                    const tags = JSON.parse(value.tags)
                    console.log(tags)
                    let target = tags.map(v => {
                        if (v.target !== undefined && v.target !== null) {
                            cible = v.target
                        }
                    })
                    console.log("target ok ", cible)
                } catch (err) {
                    console.log(err)
                }
                const item = {
                    id: value.id,
                    image: value.imageUrls[0],
                    title: value.title,
                    date: "",
                    content: value.description,
                    paragraph: value.description,
                    raised: '0',
                    goal: cible,
                }
                return item
            })
            setAllServices(arr)
        },
        onError: (err) => {
            console.log("error cause ", err)
        }

    })

    return (
        <>{allServices.length !== 0 &&
            <section className='services'>

                <Heading title={t('our services')}/>

                <div className='box-container'>
                    {
                        allServices.map((service) => {
                            return (
                                <div className='service-item' key={service.id}>
                                    <div className='image'>
                                        <img src={service.image} alt=''/>
                                    </div>
                                    <div className='content'>
                                        <Link to={`/Services/${service.id}`}
                                              location={{service}}
                                              state={service}
                                              className='main-heading'>{t(service.title)}
                                        </Link>
                                        <p dangerouslySetInnerHTML={{__html: t(service.content)}}></p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={"see-more-btn"}>
                    <Button link={'Services'} title={t('See more')}/>
                </div>

            </section>
        }</>
    )
}

export default Services
