import React, {useState} from 'react';

import { Cause, PageTitle } from '../../../components';
import { causeContent } from '../../../constants';
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";



const CausePage = () => {
  const size = 6;
    const [allCauses  , setAllCauses] = useState([])

    const getSection = useQuery({
        queryKey: "getSection cause",
        queryFn:async  () => {
            return await GetSectionItemsQuery("CAUSE")
        },
        onSuccess: (data) =>{
            console.log("cause result")
            console.log("data cause" ,data)
            const arr = data.map(value => {
                let cible = ""

                try {
                    const tags = JSON.parse(value.tags)
                    console.log(tags)
                    let target = tags.map(v => {
                        if(v.target !== undefined && v.target !== null){
                            cible = v.target
                        }
                    })
                    console.log("target ok " , cible)
                }catch (err){
                    console.log(err)
                }
                const item = {
                    id: value.id,
                    image: value.imageUrls[0],
                    title: value.title,
                    date: "",
                    content: value.description,
                    paragraph: value.description,
                    raised: '0',
                    goal: cible,
                }
                return item
            })
            console.log(arr)
            setAllCauses(arr)
        },
        onError:(err) => {
            console.log("error cause " , err)
        }

    })
  const causes = causeContent.slice(0, size);
  return (
    <>
      <PageTitle title={'our causes'} page={'causes'} />
      <section className='causes'>
        <div className='box-container'>
        {
            allCauses.map((cause) => {
                return(
                <Cause
                  key={cause.id}
                  image={cause.image}
                  title={cause.title}
                  date={cause.date}
                  content={cause.content}
                  raised={cause.raised}
                  goal={cause.goal}
                  cause={cause}
                />)
              })
            }
        </div>
      </section>
    </>
  )
}

export default CausePage;
