import React from 'react';
import { useState } from 'react';
import './PriceFilter.css';

import {Button} from '../../components';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';


const PriceFilter = () => {

    // Our States 
    const [value, setValue] =  useState(150);
  
    // Changing State when volume increases/decreases
     const rangeSelector = (value) => {
        setValue(value);
    };

    return (
    <div className='price-filter'>
      <InputRange
        maxValue={500}
        minValue={0}
        value={value}
        onChange={rangeSelector} />
        <div className='filter-btn'>
            <Button title={"FILTER"} />  
        </div>
    </div>
    
    )
}

export default PriceFilter;



