import React, {useState} from 'react';
import './SingleEvent.css';

import { EventSidebar, PageTitle, TeamItem } from '../../../components';

import { FaCalendarAlt, FaMap, FaPhone, FaEnvelope, FaUserAlt, FaCircle } from 'react-icons/fa';
import { BsPinMapFill } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';
import { GiTicket } from 'react-icons/gi';

import {useLocation, useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";
import {getImageFromData} from "../../../utils/Utils";
import {images} from "../../../constants";
import EventReactionModal from "../../../components/Modals/EventReactionModal";
import PostFaqQuery from "../../../queries/PostFaqQuery";



function SingleEvent() {

    const [questionModalIsShown , setQuestionModalIsShown] = useState(false)
    const [name , setName] = useState("")
    const [note , setNote] = useState("")
    const [error , setError] = useState("")

    const location = useLocation();
    const [event , setEvent] = useState(undefined)
    const {id} = useParams()
    const getSectionItems = useQuery({
        queryKey: "GetSectionItems",
        queryFn: async () => {
            return await GetSectionItemsQuery("EVENT")
        },
        onSuccess: (data) => {
            console.log("data ", data)
            const e = data.filter((i) => i.id === id)[0]
            console.log("event selected ", e)
            setEvent(e)
        },
        onError: (err) => {
            console.log("error ", err)
        }

    })

    const postReactionQuery = useMutation({
        mutationKey:"postReactionQuery" ,
        mutationFn: async () => {
                return await PostFaqQuery({
                    id: null,
                    eventId: id,
                    question: note,
                    askedBy: name
                })
        }
        ,
        onSuccess: () => {
            setQuestionModalIsShown(false)
        } ,
        onError: () => {
            setError("Une erreur est survenu , veuillez nous excuser")
        }
    })

    const validateModal = () => {
        console.log("name " , name)
        console.log("note " , note)
        postReactionQuery.mutate()
    }
    return (
        <>
            <PageTitle title={event !== undefined ? event.title : ""} page={'évemement'} />
            <EventReactionModal
                isVisible={questionModalIsShown}
                hasTextArea={true}
                onValid={validateModal}
                onHide={() => {setQuestionModalIsShown(!questionModalIsShown)}}
                title={"Poser votre question , réaction"}
                content={"Vous pouvez réagir , vous aurez une réponse de la part des administrateurs"}
                hasTextInput={true}
                setNote={setNote}
                setName={setName}
                name={name}
                note={note}
                error={error}
            />
            <section className='single-event'>

                {event !== undefined && <div className='event-container'>

                    <div className='event-info'>
                        <div className='image'>
                            <img src={getImageFromData(event , 0 , images.cause2 )} alt='Event'/>
                            <div className='date'>{event.date}</div>
                        </div>
                        <div className='content'>

                            <div className='details'>
                                <h3><BiTime className='icon'/><span>{event.displayDate}</span></h3>
                                <h3><FaMap className='icon'/><span>{event.location}</span></h3>
                            </div>

                            <h3 className='main-heading'>{event.title}</h3>

                            <button className={"btn"} onClick={() => {
                                setQuestionModalIsShown(!questionModalIsShown)
                            }}
                                    style={{display:"flex" , flexDirection:"row" , gap: 10 , marginBottom: 30}}
                            ><img src={images.childIcon} width={20} height={20} /><span>Reagir</span></button>

                            {
                                <p key={event.id} className='text' dangerouslySetInnerHTML={{__html:event.description}}></p>
                            }




                            {event.speakers && <div className='event-speakers'>

                                <h6>Our Speakers:</h6>

                                <div className='box-container'>
                                    {
                                        event.speakers.map((speaker) => {
                                            return (
                                                <TeamItem
                                                    key={speaker.id}
                                                    image={speaker.image}
                                                    name={speaker.name}
                                                    title={speaker.title}
                                                    accounts={speaker.accounts}
                                                    team={speaker}
                                                />)
                                        })
                                    }

                                </div>

                            </div>}

                            {event.details && <div className='event-information'>

                                <h6>Event Details:</h6>

                                <div className='box-container'>
                                    <ul>
                                        <li><FaCalendarAlt className='icon'/><h4>date:</h4><span>{event.date}</span>
                                        </li>
                                        <li><BiTime className='icon'/><h4>time:</h4><span>{event.time}</span></li>
                                        <li><BsPinMapFill className='icon'/><h4>venue:</h4><span>{event.venue}</span>
                                        </li>
                                        <li><FaMap className='icon'/><h4>location:</h4><span>{event.location}</span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><FaPhone className='icon'/><h4>phone:</h4><span>{event.phone}</span></li>
                                        <li><FaEnvelope className='icon'/><h4>email:</h4><span
                                            className='gmail'>{event.email}</span></li>
                                        <li><FaUserAlt className='icon'/><h4>organizer:</h4>
                                            <span>{event.organizer}</span></li>
                                        <li><GiTicket className='icon'/><h4>event ticket:</h4><span>${event.cost}</span>
                                        </li>
                                    </ul>

                                </div>

                                <a href="#" className='btn'>book now</a>

                            </div>}

                        </div>
                    </div>
                </div>}

                {false && <EventSidebar/>}

            </section>


        </>
    )
}

export default SingleEvent;
