import { AiTwotonePhone } from 'react-icons/ai';
import { FaEnvelope } from 'react-icons/fa';
import { BsMapFill, BsClockFill } from 'react-icons/bs';


const contacts = [
    {
        id: 1,
        icon: <AiTwotonePhone />,
        title: 'contact',
        content: [
            {id: 1, value: '+33 06 95 38 84 36'},
        ],
    },

    {
        id: 3,
        icon: <FaEnvelope />,
        title: 'email',
        content: [
            {id: 1, value: 'centre.musulman.issy@gmail.com'},
        ],
    },
    {
        id: 4,
        icon: <BsMapFill />,
        title: 'address',
        content: [
            {id: 1, value: '103 Av. de Verdun, 92130 Issy-les-Moulineaux'}
        ],
    },

];

export default contacts;
