import React, { useState } from 'react';
import './Quantity.css';



const Quantity = ({quantity}) => {

    const [click, setClick] = useState(quantity);

    const increment = () => {
      setClick(parseInt(click) + 1);
    }
     
    const decrement = () => {
      if (click > 1 ){
        setClick(parseInt(click) - 1);
      }
    }
    
  return (
    <div className='quantity buttons_added'>
        <input type="button" value="-" className='minus' onClick={decrement} />
        <input type="number" step="1" min="1" max="100" name="quantity" defaultValue={click} title="Qty" className='input-text qty text' />
        <input type="button" value="+" className='plus' onClick={increment} />
  </div>
  )
}

export default Quantity;