import React, {useEffect, useState} from 'react';

import {Footer, Header, ScrollTop} from './components';
import {
    AboutUs,
    BlogGrid,
    BlogList,
    Cart,
    CausePage,
    ChangePwd,
    Checkout,
    ContactUs,
    EditAddress,
    EditProfile,
    EventPage,
    Faqs,
    ForgotPwd,
    GalleryPage,
    HomePage,
    Login,
    OrderList,
    Profile,
    Register,
    ServicePage,
    ShopGrid,
    ShopList,
    SingleBlog,
    SingleCause,
    SingleEvent,
    SingleOrder,
    SingleProduct,
    SingleService,
    SingleTeam,
    TeamPage,
    TestimonialsPage,
    Wishlist
} from './sections';

import {Route, Routes} from "react-router-dom";

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import fr from "./locales/fr.json"
import {QueryClient, QueryClientProvider} from "react-query";
import DinPage from "./sections/DinPage/DinPage";
import DinBlog from "./sections/DinBlog/DinBlog";
import LearnPage from "./sections/LearnPage/LearnPage";
import SiteLoadPopup from "./components/SiteLoadPopup/SiteLoadPopup.js";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            fr: {
                translation: fr
            }
        },
        lng: "fr", // if you're using a language detector, do not define the lng option
        fallbackLng: "fr",

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

const App = () => {
    const queryClient = new QueryClient()
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        // Show popup when component mounts (site loads)
        setIsOpen(true);
    }, []);
    return (
        <div className='App'>
            <SiteLoadPopup />
            <QueryClientProvider client={queryClient}>
                <Header/>
                <Routes>

                    {/*Home*/}
                    <Route path='/' element={<HomePage/>}/>

                    {/*Page*/}
                    <Route path='/page/:id' element={<DinPage/>}/>
                    {/*Page*/}
                    <Route path='/blog/:id' element={<DinBlog/>}/>

                    {/*About*/}
                    <Route path='/About-us' element={<AboutUs/>}/>
                    <Route path='/Team' element={<TeamPage/>}/>
                    <Route path='/learn' element={<LearnPage/>}/>
                    <Route path='/Team/:id' element={<SingleTeam/>}/>
                    <Route path='/Team/singleTeam' element={<SingleTeam/>}/>
                    <Route path='/Testimonials' element={<TestimonialsPage/>}/>
                    <Route path='/Faqs' element={<Faqs/>}/>

                    {/*Services*/}
                    <Route path='/Services' element={<ServicePage/>}/>
                    <Route path='/Services/:id' element={<SingleService/>}/>
                    <Route path='/Services/Single-service' element={<SingleService/>}/>

                    {/*Causes*/}
                    <Route path='/Causes' element={<CausePage/>}/>
                    <Route path='/Causes/:id' element={<SingleCause/>}/>
                    <Route path='/Causes/Single-cause' element={<SingleCause/>}/>

                    <Route path='/Events/:id' element={<SingleEvent/>}/>

                    {/*Blogs*/}
                    <Route path='/Blogs' element={<EventPage/>}/>
                    <Route path='/Blogs/:id' element={<SingleEvent/>}/>
                    <Route path='/Blogs/Single-event' element={<SingleEvent/>}/>

                    {/*Pages*/}
                    <Route path='/Gallery' element={<GalleryPage/>}/> s
                    <Route path='/Login' element={<Login/>}/>
                    <Route path='/Register' element={<Register/>}/>
                    <Route path='/Forgot-pwd' element={<ForgotPwd/>}/>
                    <Route path='/Change-pwd' element={<ChangePwd/>}/>
                    <Route path='/Profile' element={<Profile/>}/>
                    <Route path='/Edit-profile' element={<EditProfile/>}/>
                    <Route path='/Edit-address' element={<EditAddress/>}/>

                    {/*Blogs*/}
                    <Route path='/Blog-grid' element={<BlogGrid/>}/>
                    <Route path='/Blog-list' element={<BlogList/>}/>
                    <Route path='/Blogs/:id' element={<SingleBlog/>}/>
                    <Route path='/Blogs/Single-blog' element={<SingleBlog/>}/>

                    {/*Shop*/}
                    <Route path='/Shop-grid' element={<ShopGrid/>}/>
                    <Route path='/Shop-list' element={<ShopList/>}/>
                    <Route path='/Shop/:id' element={<SingleProduct/>}/>
                    <Route path='/Shop/Single-product' element={<SingleProduct/>}/>
                    <Route path='/Wishlist' element={<Wishlist/>}/>
                    <Route path='/Checkout' element={<Checkout/>}/>
                    <Route path='/Cart' element={<Cart/>}/>
                    <Route path='/Order-list' element={<OrderList/>}/>
                    <Route path='/Single-order' element={<SingleOrder/>}/>
                    <Route path='/Single-order/:id' element={<SingleOrder/>}/>

                    {/*Contact*/}
                    <Route path='/contact-us' element={<ContactUs/>}/>

                </Routes>
            </QueryClientProvider>

            <Footer/>
            <ScrollTop/>

        </div>
    )
}

export default App;
