import React from 'react';
import './SingleOrder.css';

import { PageTitle, Heading, ShopSummary, Address, ShopTitle } from '../../../components';
import { profile } from '../../../constants';

import { useLocation } from "react-router-dom";

 

const OrderDetails = () => {

  const location = useLocation();  
  const order = location.state; 
  const orderItems = order.items;
  const SummaryItems = order.orderSummary;

  const addressDetails = profile.address; 

  return (   
    <>
      <PageTitle title={'single order'} page={'single order'} />
      <section className='single-order'>
 
        <div className='order-items single-order-item'>

          <div className='container'> 
            <Heading title='order items' /> 

            <ShopTitle titles = {['product', 'price', 'quantity', 'total']} />

            <div className='box-container'> 
              {
                orderItems.map((item) => {                 
                  return(
                    <div className='order-item' key={item.id}>
                      <div className='box product'>
                        <img className='image' src={item.image} alt='' />
                        <div className='name'>{item.name}</div>
                      </div>
                      <div className='box price'>${item.price}</div>
                      <div className='box quantity'>{item.quantity}</div>
                      <div className='box total'>${item.total}</div>
                    </div>
                  )              
                })
              }
            </div>
          </div>

        </div>

        <div className='single-order-intro'>

          <div className='order-summary single-order-item'>
            <div className='content'>
              <>
                <Heading title='order summary' />
                <div className='order-intro'>
                  <div className='order-id'>{order.orderID}</div>
                  <div className='delivery'>{order.delivery}</div>
                </div>
                <div className='list'>
                  <ShopSummary summary={SummaryItems} />
                </div>
              </>
            </div>

          </div>

          <div className='address-details single-order-item'>
            <Heading title='address details' />
            <div className='box-container'>
              {
                addressDetails.map((item) => {
                  return(
                    <Address 
                    key={item.id}
                    title={item.title}
                    name={item.name}
                    country={item.country}
                    city={item.city}
                    province={item.province}
                    ZIP={item.ZIP}
                    address={item.address} />
                  ) 
                })
              }
            </div>
          </div>

        </div>

      </section>
    </>
  )
}

export default OrderDetails