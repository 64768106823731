import { images } from '../constants';

const team = [ 
    {
        id: 1,
        image: images.team1,
        name: 'Saleem Yousuf',
        title: 'Volunteer',
        about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        contacts: {
            phone: '+123-456-789',
            email: 'abc@gmail.com',
            website: 'www.website.com',
            address: 'karachi, pakistan',
        },
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
        skills: [ 
            {id: 1, title: 'Knowledge Of Holy Quran'},
            {id: 2, title: 'Expertise Of Teaching Quran'},
            {id: 3, title: 'Problem Solving'},
            {id: 4, title: 'Leadership'},
            {id: 5, title: 'Creativity'},
        ],
    }, 
    {
        id: 2,
        image: images.team2,
        name: 'Zakir Khan',
        title: 'Volunteer',
        about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        contacts: {
            phone: '+123-456-789',
            email: 'abc@gmail.com',
            website: 'www.website.com',
            address: 'karachi, pakistan',
        },
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
        skills: [ 
            {id: 1, title: 'Knowledge Of Holy Quran'},
            {id: 2, title: 'Expertise Of Teaching Quran'},
            {id: 3, title: 'Problem Solving'},
            {id: 4, title: 'Leadership'},
            {id: 5, title: 'Creativity'},
        ],
    }, 
    {
        id: 3,
        image: images.team3,
        name: 'Shumaila Mushtaq',
        title: 'Volunteer',
        about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        contacts: {
            phone: '+123-456-789',
            email: 'abc@gmail.com',
            website: 'www.website.com',
            address: 'karachi, pakistan',
        },
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
        skills: [ 
            {id: 1, title: 'Knowledge Of Holy Quran'},
            {id: 2, title: 'Expertise Of Teaching Quran'},
            {id: 3, title: 'Problem Solving'},
            {id: 4, title: 'Leadership'},
            {id: 5, title: 'Creativity'},
        ],
    }, 
    {
        id: 4,
        image: images.team4,
        name: 'Shahnaz Sohail',
        title: 'Volunteer',
        about: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        contacts: {
            phone: '+123-456-789',
            email: 'abc@gmail.com',
            website: 'www.website.com',
            address: 'karachi, pakistan',
        },
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
        skills: [ 
            {id: 1, title: 'Knowledge Of Holy Quran'},
            {id: 2, title: 'Expertise Of Teaching Quran'},
            {id: 3, title: 'Problem Solving'},
            {id: 4, title: 'Leadership'},
            {id: 5, title: 'Creativity'},
        ],
    }, 
 
];

export default team;