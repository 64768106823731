import React from 'react';
import './ShopSummary.css';

const ShopSummary = ({summary}) => {
  return (
    <div className='shop-summary'>
        {  
            summary.map((item) => {
                return(
                    <div className='summary-item' key={item.id}>
                        <div className='box name'>{item.name}</div>
                        <div className='box value'>${item.value}</div>
                    </div>
                )
            }) 
        } 
    </div>
  )
}

export default ShopSummary;