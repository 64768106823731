import React from 'react';

import { Button, PageTitle } from '../../../components';

import { Link } from 'react-router-dom';



const ChangePwd = () => {  
  return ( 
    <>
      <PageTitle title={'change password'} page={'change password'} />
      <section className='change-pwd'>

        <form className='form account' action="example.php">
 
            <h3>change password!</h3>
            <input type="password" name="old-password" placeholder="enter old password" id="old-password" className='box' />
            <input type="password" name="new-password" placeholder="enter new password" id="new-password" className='box' />
            <input type="password" name="confirm-password" placeholder="confirm password" id="confirm-password" className='box' />
            <button type="submit" name="submit" id="submit"><Button title={"change password"} /></button>
            <p>don't have an account? <Link to='/register' className='link'>register</Link></p>

        </form>

      </section>
    </>

  )
}

export default ChangePwd;