import { images } from "../constants";

const wishlist = [
    {
        id: 1, 
        image: images.wishlist1,
        name: 'The Holy Quran',
        price: '50.00',
        status: 'InStock',
    },
    {
        id: 2,
        image: images.wishlist2,
        name: 'Pure Honey',
        price: '50.00', 
        status: 'InStock',
    },
    {
        id: 3,
        image: images.wishlist3,
        name: 'Dates',
        price: '50.00',
        status: 'OutOfStock',
    },
    {
        id: 4,
        image: images.wishlist4,
        name: 'Attar',
        price: '50.00',
        status: 'OutOfStock',
    },
    {
        id: 5,
        image: images.wishlist5,
        name: 'Prayer Mat',
        price: '50.00',
        status: 'InStock',
    },
];

export default wishlist;