import React from 'react';
import './Heading.css';

import { FaMosque } from 'react-icons/fa';
import {useTranslation} from "react-i18next";


const Heading = ({title}) => {
  const {t} = useTranslation()
  return (

    <div className='heading'>
      <div className='content'>

        <h2>{t(title)}</h2>
        <div className='design'>
          <span></span>
          <FaMosque className='icon' />
          <span></span>
        </div>

      </div>
    </div>

  )
}

export default Heading
