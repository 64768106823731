import React from 'react';
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import getSectionQuery from "../../../queries/GetSectionQuery";
import {getImageFromData} from "../../../utils/Utils";


const About = () => {
  const {t} = useTranslation()
  const getSection = useQuery({
    queryKey: "getSection",
    queryFn:async  () => {
      return await getSectionQuery("ABOUT")
    },
    onSuccess: (data) =>{
      console.log("data " ,data)
    },
    onError:(err) => {
      console.log("error " , err)
    }

  })
  return (
    <section className='about'>

      <Heading title={'A propos'} />

      <div className='box-container'>

        <div className='image'>
          <div className='sub-image one'>
            {getSection.isSuccess   ? <img src={getImageFromData(getSection.data , 0 , images.about1)} alt=''/> : <img src={images.about1} alt=''/>}
          </div>
          <div className='sub-image two'>

            {getSection.isSuccess   ? <img src={getImageFromData(getSection.data , 1 , images.about2)} alt=''/> : <img src={images.about2} alt=''/>}

            {getSection.isSuccess   ? <img src={getImageFromData(getSection.data , 2, images.about3)} alt=''/> : <img src={images.about3} alt=''/>}

          </div>
        </div>

        <div className='content'>
          <div style={{width: "70%" , marginLeft:"auto" , marginRight:"auto" , wordWrap:"break-word"}}>
            <h3>{getSection.isSuccess && getSection.data.title}</h3>
            <div dangerouslySetInnerHTML={{__html: getSection.isSuccess && getSection.data.description}}>
            </div>
            <Button link={'contact-us'} title={t('contact us')} />

          </div>
        </div>

      </div>

    </section>
  )
}

export default About;
