import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo, Button } from '../../components';
import { navbar } from '../../constants';

import { FaBars, FaUserAlt, FaCartArrowDown } from 'react-icons/fa';

import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../queries/GetSectionItemsQuery";



const Header = () => {
  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const {t} = useTranslation()

  const {pathname} = useLocation();

  const handleClick = () => {
    setClick(true);
    setOpen(true);
  }

  const closeMenuBar = () => {
    setClick(false);
    setOpen(false);
  }

  /* Close the Navbar between navigation */
  useEffect(() => {
    setOpen(false);
  }, [ pathname ]);

  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
        window.removeEventListener('scroll', stickyHeader);
    };
  });

  const stickyHeader = (e) => {
    const header = document.querySelector('.header-2');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
};


  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);
  };

  const removeActive = (e) => {
    const header2 = document.querySelector('.header-2');
    header2.classList.remove('active');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [removeActive])

  const [webPage , setWebPage] = useState([])
  const getSection = useQuery({
    queryKey: "getSection page",
    queryFn:async  () => {
      return await GetSectionItemsQuery("OTHER")
    },
    onSuccess: (data) =>{
      console.log("cause result")
      console.log("data cause" ,data)
      setWebPage([])
      const arr = data.map(value => {
       const page = {
          id: value.id,
              mainLink : {link: `/page/${value.id}`, title: value.title},
          subLink: [],
        }
        return page
      })
      console.log(arr)
      setWebPage(arr)
    },
    onError:(err) => {
      console.log("error cause " , err)
    }

  })

  return (

    <nav className='header'>

      <div className='header-1'>

        <Logo />

        <ul className='header-contacts'>
          <li><FaUserAlt className='icon' /><h6>{t("phone")}:</h6><span>+33 06 95 38 84 36</span></li>
          <li><FaUserAlt className='icon' /><h6>{t("email")}:</h6><span className='gmail'>centre.musulman.issy@gmail.com</span></li>
        </ul>

        { <div className='action'>

          <Button link={'https://buy.stripe.com/fZe5kZ7y58h59Gw3cf'} title={t('don')} target={"_blank"}/>
        </div>}

      </div>

      <div className='header-2'>

        {(click && isOpen) && <MobileNavbar close={closeMenuBar} webPages={webPage} />}

        <div className='navbar' >
          {
            navbar.map((item) => {
              return <HeaderItem key={item.id} {...item} />
            })
          }

          {
            webPage.map((item) => {
              return <HeaderItem key={item.id} {...item} />
            })
          }
        </div>

        <div className='icon menu-btn' id='menu' onClick={handleClick}><FaBars /></div>

      </div>

    </nav>
   )
 }

 export default Header;
