import blogs from './blogs';
import causes from './causes';
import events from './events';
import orders from './orders';
import products from './products';
import services from './services';
import team from './team';



const navbar = [
    {
        id: 1,
        mainLink : {link: '/', title: 'Accueil'},
        subLink: [],
    },

    /*{
        id: 3,
        mainLink : {link: '/', title: 'Le projet'},
        subLink: [],
    },
    {
        id: 4,
        mainLink : {link: '#', title: 'Nos évènements'},
        subLink: [],
    },
    {
        id: 5,
        mainLink : {link: '#', title: 'Dons'},
        subLink: [],
    },*/
    {
        id: 6,
        mainLink : {link: '/contact-us', title: 'Contact'},
        subLink: [],
    },
    {
        id: 7,
        mainLink : {link: '/learn', title: 'Enseignement'},
        subLink: [],
    }
    /*{
        id: 7,
        mainLink : {link: '#', title: 'Enseignements'},
        subLink: [],
    },*/
    /*{
        id: 8,
        mainLink : {link: '#', title: 'shop'},
        subLink: [
            {id: 1, link: '/Shop-grid', title: 'shop grid'},
            {id: 2, link: '/Shop-list', title: 'shop list'},
            {id: 3, link: '/Shop/Single-product', title: 'single product', state: products[0]},
            {id: 4, link: '/Wishlist', title: 'wishlist'},
            {id: 5, link: '/Checkout', title: 'checkout'},
            {id: 6, link: '/Cart', title: 'cart'},
            {id: 7, link: '/Order-list', title: 'order list'},
            {id: 8, link: '/Single-order', title: 'single order', state: orders[0]},
        ],
    },
    {
        id: 9,
        mainLink : {link: '/Contact-us', title: 'contact'},
        subLink: [],
    },*/
];

export default navbar;
