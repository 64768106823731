import React from 'react';
import './Wishlist.css';

import { PageTitle, ShopTitle } from '../../../components';
import { wishlist } from '../../../constants';

import { BsFillCartPlusFill } from 'react-icons/bs';
import { RiDeleteBin5Fill } from 'react-icons/ri';

import { Link } from 'react-router-dom'; 



const Wishlist = () => {
  return (   
    <>
      <PageTitle title={'wishlist'} page={'wishlist'} />
      <section className='wishlist'>

        <div className='wishlist-container'>

          <div className='container'> 

            <ShopTitle titles = {['product', 'price', 'status', 'activity']} />

            <div className='box-container'>
              {
                wishlist.map((item) => {
                  return(
                    <div className='wishlist-item' key={item.id}>
                      <div className='box product'>
                        <img className='image' src={item.image} alt='' />
                        <div className='name'>{item.name}</div>
                      </div>
                      <div className='box price'>{item.price}</div>
                      <div className={`box status ${item.status}`}>{item.status}</div>
                      <div className='box action'>
                        <Link to='/#'><RiDeleteBin5Fill className='icon delete' /></Link> 
                        <Link to='/#'><BsFillCartPlusFill className='icon cart' /></Link> 
                      </div>
                  </div>
                  )
                })
              }
            </div>

          </div>
        </div>
 
      </section>
    </>
  )
}

export default Wishlist;