import React from 'react';
import './OrderList.css';

import { PageTitle, ShopTitle } from '../../../components';
import { orders } from '../../../constants';

import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
 
 

const OrderList = () => {
  return (
    <>
      <PageTitle title={'order list'} page={'order list'} />
      <section className='order-list'> 

        <div className='order-list-container'>
 
          <div className='container'> 

            <ShopTitle titles = {['order#ID', 'date', 'total', 'status', 'action']} />

            <div className='box-container'>
              {
                orders.map((item) => {
                  return(
                    <div className='item' key={item.id}>
                      <div className='box order-id'>{item.orderID}</div>
                      <div className='box price'>{item.date}</div>
                      <div className='box total'>${item.orderSummary[0].value}</div>
                      <div className={`box status ${item.status}`}>{item.status}</div>
                      <div className='box action'>
                        <Link to={{
                            pathname: '/Single-order/' + item.orderID,
                          }}     
                          location = {{item}}
                          state= {item}
                          className='icon'><FaEye />
                        </Link>
                      </div>
                    </div>
                  )
                })
              }

            </div>

          </div>

        </div>
      </section>
    </>
  )
}

export default OrderList;