import React, {useState, useEffect} from 'react';
import './Gallery.css';
 
import { GalleryItem, GalleryList } from '../../components';
import { galleryContent } from '../../constants';

import { LightBox } from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css"; 

   
   
const Gallery = () => {

  // State
  const [tag, setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

	const [toggle, setToggle] = useState(false);
	const [sIndex, setSIndex] = useState(0);

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};
 
  const popupImage = (index) => {
    lightBoxHandler(true, index);
  } 

  useEffect( () => {
    tag == 'all' ? setFilteredImages(galleryContent) : setFilteredImages(galleryContent.filter((image) => image.tag == tag))
  }, [tag])

  const list = [
    {
      id: "all", 
      title: "all",
    },
    {
      id: "learning",
      title: "Quran Learning",
    },
    {
      id: "community",
      title: "community service", 
    },
    {
      id: "mosque",
      title: "mosque development",
    }, 
  ];
 
  
  return (
    <>
    <div className='gallery'>

      <ul className='controls'>
        { 
          list.map((item) => {
            return(
              <GalleryList
              key={item.id} 
              title={item.title}
              setSelected={setTag}
              active={tag == item.id ? true: false }
              />
            ) 
          })
        }
      </ul> 

      <div className='box-container'>
        {
          filteredImages.map((item, index) => {
            return(
              <GalleryItem
              key={item.id}
              image={item.image}
              title={item.title}
              category={item.category}
              size={item.size}
              link={item.link}
              index={index}
              popup={popupImage}
              /> 

            )
          })
        }

        <LightBox
          state={toggle}
          event={lightBoxHandler}
          data={galleryContent}
          imageWidth="60vw"
          imageHeight="70vh"
          thumbnailHeight={50}
          thumbnailWidth={50}
          setImageIndex={setSIndex}
          imageIndex={sIndex}
        />
          
      </div>

    </div>
    </>

  )
}

export default Gallery;