export const getImageFromData= (data , index ,defaultImage) => {
    if(data.imageUrls !== undefined){
        if(data.imageUrls.length >  index){
            return  data.imageUrls[index].trim().length === 0 ? defaultImage : data.imageUrls[index]
        }else{
            return  defaultImage
        }
    }else{
        return defaultImage
    }
}
