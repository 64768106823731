import axios from "axios";


const Network = () => {
    console.log("url" , "http://localhost:8080/api")
    const api = axios.create({
        //baseURL: `${import.meta.env.VITE_API_URL}`
        baseURL: `https://cmi-issy-api-prod.onrender.com/api/`
        //baseURL: `http://localhost:8080/api/`
    })
    /*api.interceptors.request.use((config) =>{
        const token = localStorage.getItem(CMI_TOKEN)
        console.log("massar token " , token)

        if(token !== undefined && token !== null && token.trim().length !== ""){
            config.headers.Authorization= `Bearer ${token.replace(/\"/g , "")}`
        }
        return config
    } , (error) => {})*/
    return api
}

export default Network
