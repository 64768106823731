import React, {useEffect, useState} from 'react';
import './DinBlog.css';

import { EventSidebar, PageTitle, TeamItem } from '../../components';

import { FaCalendarAlt, FaMap, FaPhone, FaEnvelope, FaUserAlt, FaCircle } from 'react-icons/fa';
import { BsPinMapFill } from 'react-icons/bs';
import { BiTime } from 'react-icons/bi';
import { GiTicket } from 'react-icons/gi';

import {useLocation, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../queries/GetSectionItemsQuery";
import {getImageFromData} from "../../utils/Utils";



function DinBlog() {

    const location = useLocation();
    const [event , setEvent] = useState(undefined)
    const {id} = useParams()
    const getSectionItems = useQuery({
        queryKey: "GetSectionItems",
        queryFn: async () => {
            return await GetSectionItemsQuery("TEACH")
        },
        onSuccess: (data) => {
            console.log("data ", data)
            const e = data.filter((i) => i.id === id)[0]
            console.log("event selected ", e)
            setEvent(e)
        },
        onError: (err) => {
            console.log("error ", err)
        }

    })

    useEffect(() => {
        getSectionItems.refetch()
    } , [id])

    return (
        <>
            <PageTitle title={event !== undefined ? event.title : ""} page={''} />
            <section className='single-event'>

                {event !== undefined && <div className='event-container'>

                    <div className='event-info'>

                        <div className='content'>



                            <h3 className='main-heading'>{event.title}</h3>

                            {
                                <p key={event.id} className='text' dangerouslySetInnerHTML={{__html:event.description}}></p>
                            }


                            {event.speakers && <div className='event-speakers'>

                                <h6>Our Speakers:</h6>

                                <div className='box-container'>
                                    {
                                        event.speakers.map((speaker) => {
                                            return (
                                                <TeamItem
                                                    key={speaker.id}
                                                    image={speaker.image}
                                                    name={speaker.name}
                                                    title={speaker.title}
                                                    accounts={speaker.accounts}
                                                    team={speaker}
                                                />)
                                        })
                                    }

                                </div>

                            </div>}

                            {event.details && <div className='event-information'>

                                <h6>Event Details:</h6>

                                <div className='box-container'>
                                    <ul>
                                        <li><FaCalendarAlt className='icon'/><h4>date:</h4><span>{event.date}</span>
                                        </li>
                                        <li><BiTime className='icon'/><h4>time:</h4><span>{event.time}</span></li>
                                        <li><BsPinMapFill className='icon'/><h4>venue:</h4><span>{event.venue}</span>
                                        </li>
                                        <li><FaMap className='icon'/><h4>location:</h4><span>{event.location}</span>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li><FaPhone className='icon'/><h4>phone:</h4><span>{event.phone}</span></li>
                                        <li><FaEnvelope className='icon'/><h4>email:</h4><span
                                            className='gmail'>{event.email}</span></li>
                                        <li><FaUserAlt className='icon'/><h4>organizer:</h4>
                                            <span>{event.organizer}</span></li>
                                        <li><GiTicket className='icon'/><h4>event ticket:</h4><span>${event.cost}</span>
                                        </li>
                                    </ul>

                                </div>

                                <a href="#" className='btn'>book now</a>

                            </div>}

                        </div>
                    </div>
                </div>}

                {false && <EventSidebar/>}

            </section>


        </>
    )
}

export default DinBlog;
