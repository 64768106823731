import React, {useState} from 'react';
import './CauseSidebar.css';

import { SidebarHeading, Cause } from '../../components';
import { causeCategories, causePosts, causeTags } from '../../constants';

import { BsSearch } from 'react-icons/bs';
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../queries/GetSectionItemsQuery";
import GetSectionQuery from "../../queries/GetSectionQuery";



const CauseSidebar = () => {
    const [allCauses  , setAllCauses] = useState([])

    const getSection = useQuery({
        queryKey: "getSection cause",
        queryFn:async  () => {
            return await GetSectionItemsQuery("CAUSE")
        },
        onSuccess: (data) =>{
            console.log("cause result")
            console.log("data cause" ,data)
            const arr = data.map(value => {
                let cible = ""
                let causePaymentLink = ""

                try {
                    const tags = JSON.parse(value.tags)
                    console.log(tags)
                    let target = tags.map(v => {
                        if(v.target !== undefined && v.target !== null){
                            cible = v.target
                        }
                    })

                    let causePLink = tags.map(v => {
                        if(v.paymentLink !== undefined && v.paymentLink !== null){
                            cible = v.paymentLink
                        }
                    })
                    console.log("target ok " , cible)
                }catch (err){
                    console.log(err)
                }
                const item = {
                    id: value.id,
                    image: value.imageUrls[0],
                    title: value.title,
                    date: "",
                    content: value.description,
                    paragraph: value.description,
                    raised: '0',
                    goal: cible,
                    link: causePaymentLink
                }
                return item
            })
            console.log(arr)
            setAllCauses(arr)
        },
        onError:(err) => {
            console.log("error cause " , err)
        }

    })
  return (
    <div className='sidebar cause-sidebar'>

        <div className='search sidebar-item'>
            <SidebarHeading title='search' />
            <div className='input-box'>
                <input type="search" id="search" placeholder="search..." />
                <label htmlFor="search"><BsSearch /></label>
            </div>
        </div>





        <div className='recent-post sidebar-item'>
            <SidebarHeading title='recent posts' />
            <div className='box-container'>
            {
                allCauses.map((cause) => {
                    return(
                        <Cause
                        key={cause.id}
                        image={cause.image}
                        title={cause.title}
                        date={cause.date}
                        cause={cause}
                        link={cause.link}
                      />)
                })
            }
            </div>
        </div>





    </div>
  )
}

export default CauseSidebar;
