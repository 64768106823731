import { images } from '../constants'; 

const blogs = [   
    {   
        id: 1, 
        image: images.blog1,
        date : '25th Feb, 2022',
        admin: 'admin',
        heading: 'How To Perform Salah?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog1gallery1},
            {id: 2, image: images.blog1gallery2},
            {id: 3, image: images.blog1gallery3},
            {id: 4, image: images.blog1gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.blog2,
        date : '16th mar, 2022',
        admin: 'admin',
        heading: 'Importance of Ramadan',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog2gallery1},
            {id: 2, image: images.blog2gallery2},
            {id: 3, image: images.blog2gallery3},
            {id: 4, image: images.blog2gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog2comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog2comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog2comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.blog3,
        date : '2nd may, 2022',
        admin: 'admin',
        heading: 'How to read Quran?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog3gallery1},
            {id: 2, image: images.blog3gallery2},
            {id: 3, image: images.blog3gallery3},
            {id: 4, image: images.blog3gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog3comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog3comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog3comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 4, 
        image: images.blog4,
        date : '7th july, 2022',
        admin: 'admin',
        heading: 'Why Islam gives best purpose?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog4gallery1},
            {id: 2, image: images.blog4gallery2},
            {id: 3, image: images.blog4gallery3},
            {id: 4, image: images.blog4gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog4comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog4comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog4comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 5, 
        image: images.blog5,
        date : '29th sep, 2022',
        admin: 'admin',
        heading: 'What is Islamic lifestyle?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog5gallery1},
            {id: 2, image: images.blog5gallery2},
            {id: 3, image: images.blog5gallery3},
            {id: 4, image: images.blog5gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog5comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog5comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog5comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 6, 
        image: images.blog6,
        date : '20th oct, 2022',
        admin: 'admin',
        heading: 'How to spend Life?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog6gallery1},
            {id: 2, image: images.blog6gallery2},
            {id: 3, image: images.blog6gallery3},
            {id: 4, image: images.blog6gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export default blogs;