import { images } from '../constants';

export const categories = [  
    {
        id: 1,
        title: 'Learning Quran',
        quantity: '20',
    },
    {
        id: 2,
        title: 'Marriage Service',
        quantity: '5',
    }, 
    {
        id: 3, 
        title: 'Counseling',
        quantity: '10',
    },
    { 
        id: 4,
        title: 'Community service',
        quantity: '15',
    },
    { 
        id: 5,
        title: 'Mosque Development',
        quantity: '8',
    },
    {
        id: 6,
        title: 'Funeral Service',
        quantity: '4',
    },
];

export const posts = [ 
    {   
        id: 2, 
        image: images.post1,
        date : '16th mar, 2022',
        admin: 'admin',
        heading: 'Importance of Ramadan',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post1gallery1},
            {id: 2, image: images.post1gallery2},
            {id: 3, image: images.post1gallery3},
            {id: 4, image: images.post1gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.post1comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post1comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post1comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.post2,
        date : '2nd may, 2022',
        admin: 'admin',
        heading: 'How to read Quran?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post2gallery1},
            {id: 2, image: images.post2gallery2},
            {id: 3, image: images.post2gallery3},
            {id: 4, image: images.post2gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.post2comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post2comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post2comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 6, 
        image: images.post3,
        date : '20th oct, 2022',
        admin: 'admin',
        heading: 'How to spend Life?',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.post3gallery1},
            {id: 2, image: images.post3gallery2},
            {id: 3, image: images.post3gallery3},
            {id: 4, image: images.post3gallery4},
        ],
        points: [ 
            {id: 1, title: 'Invoking Allah the Almighty'},
            {id: 2, title: 'enhances your Taqwa towards Allah the Merciful'},
            {id: 3, title: 'Gain Rewards in Akhirah (Afterworld)'},
            {id: 4, title: 'Staying connected with Allah'},
            {id: 5, title: 'Protection from Shaytaan'},
            {id: 6, title: 'Prevention from Lewdness'},
            {id: 7, title: 'A Means to Expiate your sins'},
            {id: 8, title: 'Helps in Soothing Cares and our Worries'},
        ],
        tags: [ 
            {id: 1, title: 'worship'},
            {id: 2, title: 'quran'},
            {id: 3, title: 'health'},
        ],
        comments: [
            {
                id: 1,
                image: images.post3comment1,
                name: 'David Bairstow',
                date: 'feb 1st, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.post3comment2,
                        name: 'Amy Jones',
                        date: 'june 20th, 2022',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.post3comment3,
                name: 'Susie Rowe',
                date: 'sep 23rd, 2022',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export const tags = [
    {
        id: 1,
        title: 'books',
    },
    {
        id: 2,
        title: 'worship',
    },
    {
        id: 3,
        title: 'education',
    },
    {
        id: 4,
        title: 'Health', 
    },
    {
        id: 5,
        title: 'Donate', 
    },
    {
        id: 6,
        title: 'Quran',
    }, 
];