import {Event, Heading, PageTitle} from "../../components";
import {getImageFromData} from "../../utils/Utils";
import React from "react";
import {useTranslation} from "react-i18next";
import Images from "../../constants/images";

const LearnPage = () => {
    const {t} = useTranslation()

    return <>
        <PageTitle title={"Enseignement"} page={'service'} />
        <section className='single-service'>

            <div className='service-info'>
                <div className='image'>
                    <img src={Images.LearnImage} alt='Service' />
                </div>
                <div className='content'>
                    <h3 className='main-heading'>{"Enseignement"}</h3>
                    <p>
                        "L'arabe est une langue qui nous pousse à l'admiration, par sa beauté et sa complexité, et dont la poésie est incomparable."
                        Goethe, poète et écrivain allemand
                    </p>
                    <p>

                        L'école d'arabe a inauguré ses premiers cours en 2012. Au cours de cette année inaugurale, le nombre d'élèves inscrits était modeste. Depuis, l'école du Centre Musulman d'Issy-les-Moulineaux a considérablement évolué, permettant d'accueillir plusieurs classes composées chacune de plusieurs élèves. Notre programme d'enseignement de la langue arabe est conçu pour être accessible à un public diversifié, qu'il soit arabophone ou non, et ce, indépendamment de l'âge et de l'origine des apprenants.

                        Les élèves sont répartis dans des classes en fonction de leur niveau de compétence. Nous proposons ainsi plusieurs niveaux d'apprentissage, allant du préparatoire au niveau 6. Chaque année, notre programme est ajusté et amélioré dans le but d'optimiser l'apprentissage et de rehausser le niveau de nos élèves.

                        Les cours sont principalement dispensés en présentiel, au sein de salles de classe équipées. Les supports pédagogiques utilisés incluent des livres, des manuels et des cahiers. Ces supports peuvent être complétés par des ressources électroniques, telles qu'internet et diverses applications. De plus, en cas de besoin, nous sommes en mesure de proposer des cours à distance afin de garantir la continuité de l'enseignement.

                        Les cours sont généralement organisés en séances de trois heures, réparties sur les samedis ou dimanches ou mercredis. Une pause récréative est prévue pour les élèves les plus jeunes. Pendant les séances, l'enseignement se concentre sur le développement des compétences en lecture, écriture et compréhension de la langue arabe, à travers des exercices pratiques et interactifs.

                        Notre mission est de fournir une éducation de qualité, adaptée aux besoins spécifiques de chaque élève, et de promouvoir la maîtrise de la langue arabe dans un environnement pédagogique stimulant et bienveillant.
                    </p>

                    <p>
                        Veuillez remplir le formulaire adéquat et l'envoyer à <a href="mailto:centre.musulman.issy@gmail.com">centre.musulman.issy@gmail.com</a> pour compléter votre inscription
                    </p>

                    <p>
                        <h3>Adulte</h3>
                        <a target={"_blank"} href={"https://storage.googleapis.com/moods-996c3.appspot.com/enseignement/Fiche%20inscription%20Adulte%202024-2025.pdf"}>Fiche inscription Adulte 2024-2025.pdf</a>
                        <h3>Enfant</h3>
                        <a target={"_blank"} href={"https://storage.googleapis.com/moods-996c3.appspot.com/enseignement/Fiche%20inscription%20enfants%202024-2025.pdf"}>Fiche inscription enfants 2024-2025.pdf</a>


                        <h3>Document utils</h3>
                        <a target={"_blank"} href={"https://storage.googleapis.com/moods-996c3.appspot.com/enseignement/Planning%20cours%202024-2025.pdf"}>Planning cours 2024-2025.pdf</a>
                        <a target={"_blank"} href={"https://storage.googleapis.com/moods-996c3.appspot.com/enseignement/Jours%20inscritpions%202024-2025.pdf"}>Jours inscritpions 2024-2025.pdf</a>

                    </p>

                    <p>
                        Pour toute question ou assistance, n'hésitez pas à contacter notre service d'assistance à l'adresse email suivante : <a href="mailto:centre.musulman.issy@gmail.com">centre.musulman.issy@gmail.com</a> ou par téléphone au <a href={"tel:+33 06 95 38 84 36"}>+33 06 95 38 84 36</a> .
                    </p>






                </div>

            </div>



        </section>
    </>
}

export default LearnPage
