import React from 'react';

import { Button, PageTitle } from '../../../components';

import { Link } from 'react-router-dom';



const ForgotPwd = () => {
  return (   
    <> 
      <PageTitle title={'Reset Password'} page={'Reset Password'} />
      <section className='reset-pwd'>
 
        <form className='form account' action="example.php">
            <h3>forgot password!</h3>
            <input type="email" name="email" placeholder="enter your email" id="email" className='box' />
            <button type="submit" name="submit" id="submit"><Button title={"send reset link"} /></button>
            <p>don't have an account? <Link to='/register' className='link'>register</Link></p>
        </form>

      </section>
    </>

  )
}

export default ForgotPwd;