import React from 'react';
import './Logo.css';

import { images } from '../../constants';

import { Link } from "react-router-dom";



const Logo = () => {
  return (
    <Link to='/'  className='logo'>
      <img src={images.logo} alt='header-logo' />
      <div className='logo-name'>
        <h3>CMI</h3>
        <p>Issy les moulineaux</p>
      </div>
    </Link>
  )
}

export default Logo;
