const testimonials = [ 
    { 
        id: 1,
        name: 'Haroon Rasheed', 
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!',
    },
    {
        id: 2,
        name: 'Rabia Khan',
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!',
    },
    { 
        id: 3,
        name: 'Mohammad Nazir',
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!', 
    },
    { 
        id: 4,
        name: 'Humera Masroor',
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!', 
    },
];

export default testimonials;