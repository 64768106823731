import React from 'react';
import './SingleCause.css';

import { CauseSidebar, PageTitle } from '../../../components';

import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsCurrencyDollar } from 'react-icons/bs';

import { useLocation } from "react-router-dom";



function SingleCause() {

    const location = useLocation();
    const cause = location.state;

    return (
        <>
            <PageTitle title={cause.title} page={'causes'} />
            <section className='single-cause'>

                <div className='cause-info'>
                    <div className='image'>
                        <img src={cause.image} alt='Cause' />
                    </div>

                    <div className='intro'>
                        <h3 className='main-heading'>{cause.title}</h3>
                        <div className='date'><AiTwotoneCalendar className='icon' /><span>{cause.date}</span></div>
                        <div className='skill-bar'>
                            <span className='skill-progress' style={{width: Math.floor((cause.raised * 100) / cause.goal) + `%`}}>
                                <span className='skill-no'>{Math.floor((cause.raised * 100) / cause.goal)}%</span>
                            </span>
                        </div>
                        <div className='amount'>
                            <p>Raised: <span>${cause.raised}</span></p>
                            <p>Goal: <span>${cause.goal}</span></p>
                        </div>
                    </div>

                    <form className='form content' action="comment.php">

                        <p dangerouslySetInnerHTML={{__html:cause.paragraph}}></p>


                        <button type="submit" className='btn' name="donate" id="donate" onClick={(event) => {
                            event.preventDefault()
                            console.log("cause link " , cause.link)
                            window.location.href = cause.link

                        }}>donate now</button>

                    </form>

                </div>

                <CauseSidebar />
            </section>
        </>
    )
}

export default SingleCause;
