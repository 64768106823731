import { images } from '../constants';


export const categories = [ 
    {
        id: 1,
        title: 'all',
        quantity: '50',
    }, 
    {
        id: 2,
        title: 'books',
        quantity: '15',
    }, 
    {
        id: 3, 
        title: 'clothing',
        quantity: '10',
    },
    {
        id: 4,
        title: 'fragrance',
        quantity: '8',
    },
    {
        id: 5,
        title: 'worship',
        quantity: '10',
    },
    {
        id: 6,
        title: 'gifts',
        quantity: '7',
    },
];
 
export const popular = [
    {
        id: 1,
        image: images.popular1Gallery1, 
        name: 'The Holy Quran',
        reviews: '100+', 
        price: '30.00', 
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.popular1Gallery1,
            image2: images.popular1Gallery2,
            image3: images.popular1Gallery3,
            image4: images.popular1Gallery4,
        },
    },
    {
        id: 2,
        image: images.popular2Gallery1, 
        name: 'Dates',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.popular2Gallery1,
            image2: images.popular2Gallery2,
            image3: images.popular2Gallery3,
            image4: images.popular2Gallery4,
        },
    },
    {
        id: 3,
        image: images.popular3Gallery1, 
        name: 'Perfume',
        reviews: '100+', 
        price: '30.00',
        disprice: '20.00',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'books',
            'worship'
        ], 
        tags: [
            'books',
            'worship',
            'education'
        ],
        gallery: {
            image1: images.popular3Gallery1,
            image2: images.popular3Gallery2,
            image3: images.popular3Gallery3,
            image4: images.popular3Gallery4,
        },
    },
];

