import React, {useState} from 'react';
import './SingleService.css';

import { FaqItem, PageTitle, SidebarHeading } from '../../../components';
import {images, serviceContent} from '../../../constants';

import { MdDoubleArrow } from 'react-icons/md';

import {useLocation, useParams} from "react-router-dom";
import { Link } from 'react-router-dom';
import {useQuery} from "react-query";
import GetSectionItemsQuery from "../../../queries/GetSectionItemsQuery";
import {getImageFromData} from "../../../utils/Utils";



function ServiceDetails() {
    const [service  , setService] = useState({})

    const location = useLocation();
    const [allCauses  , setAllCauses] = useState([])

    const {id} = useParams()
    const getSection = useQuery({
        queryKey: "getSection servcice",
        queryFn:async  () => {
            return await GetSectionItemsQuery("SERVICE")
        },
        onSuccess: (data) =>{
            console.log("cause result")
            console.log("data cause" ,data)
            const arr = data.map(value => {
                let cible = ""
                let causePaymentLink = ""


                const item = {
                    id: value.id,
                    image: getImageFromData(value , 0 , images.cause2 ),
                    title: value.title,
                    date: "",
                    content: value.description,
                    paragraph: value.description,
                    raised: '0',
                    goal: cible,
                    link: causePaymentLink
                }
                return item
            })
            setService(arr.filter(v => v.id === id)[0])
        },
        onError:(err) => {
            console.log("error cause " , err)
        }

    })





    return (
        <>
            <PageTitle title={service.title} page={'service'} />
            <section className='single-service'>

                <div className='service-info'>
                    <div className='image'>
                        <img src={service.image} alt='Service' />
                    </div>
                    <div className='content'>
                        <h3 className='main-heading'>{service.title}</h3>

                        <p dangerouslySetInnerHTML={{__html:service.paragraph}} />

                        <div className='service-gallery'>
                            <img key={service.id} src={service.image} alt='' />
                        </div>




                    </div>

                </div>



            </section>
        </>
    )
}

export default ServiceDetails;
