import React from 'react';
import './Profile.css';

import { Address, Button, Heading, PageTitle } from '../../../components';
import { profile } from '../../../constants';

import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsEnvelopeFill } from 'react-icons/bs';
import { ImMobile } from 'react-icons/im';
   
const Profile = () => { 
    const addressDetails = profile.address;
  return ( 
     <> 
        <PageTitle title={'profile'} page={'profile'} />
        <section className='profile'>

            <div className='box-container'> 
                
                <div className='profile-item profile-details'>
                    <Heading title={'profile details'} />
                    <div className='content'>
                        <div className='image'>
                            <img src={profile.image} alt="" />
                        </div>
                        <div className='info'>
                            <h3>{profile.name}</h3>
                            <div className='container'>
                                <div className='box'><BsEnvelopeFill className='icon' /><span className='gmail'>{profile.gmail}</span></div>
                                <div className='box'><ImMobile className='icon' /><span>{profile.contactNo}</span></div>
                                <div className='box'><FaMapMarkerAlt className='icon' /><span>{profile.addressDetails}</span></div>
                                <Button link={'/edit-profile'} title={'edit profile'} />
                            </div>
                        </div>
                    </div> 
                </div>
 
                <div className='profile-item address-details'>
                    <Heading title={'address details'} />
                    <div className='content'>
                        <div className='address-container'>
                            {
                                addressDetails.map((item) => {
                                    return(
                                        <Address 
                                        key={item.id}
                                        title={item.title}
                                        name={item.name}
                                        country={item.country}
                                        city={item.city}
                                        province={item.province}
                                        ZIP={item.ZIP}
                                        address={item.address} />
                                    )
                                })
                            }
                        </div> 
                        <Button link={'/edit-address'} title={'edit address'} />
                    </div>

                </div>
                
            </div>

        </section>
    </> 
  )
}

export default Profile;