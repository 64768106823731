import React from 'react';

import { PageTitle } from '../../../components';
import { Services} from '../../../sections';



const ServicePage = () => {
  return (
    <>
      <PageTitle title={'services'} page={'services'} />
      <Services />
    </>
  )
}

export default ServicePage;