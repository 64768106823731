import React from 'react';
import './SingleTeam.css';

import { PageTitle } from '../../../components';

import { FaPhone, FaEnvelope, FaGlobe, FaMap, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { BsCircleFill } from 'react-icons/bs';

import { useLocation } from "react-router-dom";
 


const SingleTeam = () => { 

  const location = useLocation();
  const team = location.state; 

  const contact = team.contacts; 
  const account = team.accounts; 

  return (
    <>
      <PageTitle title={'single team'} page={'single team'} />
        <section className='single-team'>

          <div className='team-intro'> 

            <div className='image'>
                <img src={team.image} alt="" />
            </div>

            <div className='content'>

              <div className='intro'>
                <h3>{team.name}</h3>
                <p>{team.title}</p>
              </div>

              <div className='contact-details'>
                <h4>contact details:</h4>
                <div className='box-container'>
                  <div className='item'>
                    <p><FaPhone className='icon' /><span>{contact.phone}</span></p>
                    <p><FaEnvelope className='icon' /><span>{contact.email}</span></p>
                    <p><FaGlobe className='icon' /><span>{contact.website}</span></p>
                    <p><FaMap className='icon' /><span>{contact.address}</span></p>
                  </div>
                  <div className='item'>
                    <p><FaFacebookF className='icon' /><span>{account.facebook}</span></p>
                    <p><FaTwitter className='icon' /><span>{account.twitter}</span></p>
                    <p><AiFillInstagram className='icon' /><span>{account.instagram}</span></p>
                    <p><FaYoutube className='icon' /><span>{account.youtube}</span></p>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className='team-about'>
            <h4>About Scholar</h4>
            <p>{team.about}</p>
          </div>

          <div className='team-skills'>
            <h4>Skills</h4>
            <ul>
            `{
              (team.skills).map((item) => {
                  return <li key={item.id}><BsCircleFill className='icon' /><span>{item.title}</span></li>
              })
            }
            </ul>
          </div> 


        </section>
    </>
  ) 
}

export default SingleTeam;